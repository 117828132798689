import { Cloudinary } from "@cloudinary/url-gen";

export function UserDefault(user) {

	// Create a Cloudinary instance and set your cloud name.
	const cld = new Cloudinary({
		cloud: {
			cloudName: 'tmlive'
		}
	});

	const myImage = cld.image(`user/${user}`);

	return myImage;
}
