import React, { Component } from "react";


/**
 * Team
 * 
 * team: Object
 */
export default class Team extends Component {
	addDefaultSrc = (e) => {
		e.target.src =
			"https://res.cloudinary.com/tmlive/image/upload/v1649784964/teams/default.png";
	};
	render() {
		return (
			<div className="flex ml-3">
				<img
					className="w-12 h-12 rounded-md"
					src={this.props.team.avatar}
					alt="Team"
					onError={this.addDefaultSrc}
				/>

				<div className="w-full flex items-center ml-5">
					{this.props.team.name}
				</div>
			</div>
		);
	}
}
