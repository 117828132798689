import React, { Component } from "react";
import Team from "./Team";

/**
 * Match
 *
 * match: Object
 * currentMatch: Object
 * changeCurrentMatch: Function
 */
export default class Match extends Component {
	backgroundColor = () => {
		if (
			this.props.currentMatch &&
			this.props.currentMatch.match_id === this.props.match.match_id
		) {
			return "bg-black-500";
		}
		switch (this.props.match.status) {
			case "SCHEDULED":
				return "bg-gray-400 text-black";
			case "FINISHED":
				return "bg-gray-200 text-black";
			case "CANCELLED":
				return "bg-gray-500 text-black";
			case "ONGOING":
				return "bg-lime-500";
			case "VOTING":
				return "bg-red-500";
			case "READY":
				return "bg-azure-500";
			default:
				break;
		}
	};

	render() {
		return (
			<div
				onClick={() =>
					this.props.changeCurrentMatch(this.props.match.match_id)
				}
				className={
					"border-2 rounded-lg border-white flex p-2 " +
					this.backgroundColor()
				}
			>
				<div className="w-full flex flex-col space-y-1">
					<Team team={this.props.match.teams.faction1} />
					<hr />
					<Team team={this.props.match.teams.faction2} />
					<div className="w-full text-center">
						{this.props.match.match_id}
					</div>
				</div>
			</div>
		);
	}
}
