import React from "react";

import { ThemeContext } from "../../context/theme-context";

import OverlayButtons from "./OverlayButtons";

import FormInput from "../UIElements/Forms/FormInput";
import FormCheckbox from "../UIElements/Forms/FormCheckbox";
import FormSelect from "../UIElements/Forms/FormSelect";
import FormMultiInput from "../UIElements/Forms/FormMultiInput";
import FormCountry from "../UIElements/Forms/FormCountry";
import Loading from "../UIElements/Loading";
import FormSocials from "../UIElements/Forms/FormSocials";

/**
 * OverlayEdit
 *
 * data: Object
 * saveURL: Function (url)
 * changeOverlay: Function (currentData)
 * overlayArray: Array
 * addCurrent: Function
 * saveCurrent: Function
 * onChange: Function (event, extra)
 * overlayAction: String
 * currentAPI: String
 */
export default class OverlayEdit extends React.Component {
	static contextType = ThemeContext;
	state = {
		loading: true,
		uploader: false,
		games: [],
		orgas: [],
	};

	componentDidMount = () => {
		if (this.props.overlayArray.includes("game")) {
			fetch(`${this.context.server}/content/games?sort=name`, {
				method: "GET",
				headers: { Authorization: "Bearer " + this.context.token },
			})
				.then((response) => response.json())
				.then((resp) => {
					this.setState({
						games: resp.data,
					});
				})
				.catch((err) => {
					this.context.checkError({ message: err.message });
				});
		}
		if (this.props.overlayArray.includes("orga")) {
			fetch(`${this.context.server}/content/orgas?sort=name`, {
				method: "GET",
				headers: { Authorization: "Bearer " + this.context.token },
			})
				.then((response) => response.json())
				.then((resp) => {
					this.setState({
						orgas: resp.data,
						loading: false,
					});
				})
				.catch((err) => {
					this.context.checkError({ message: err.message });
				});
		}
	};

	openUploader = (e) => {
		e.preventDefault();
		this.setState({ uploader: !this.state.uploader });
	};

	colors = [
		"bg-azure-600",
		"bg-cyan-600",
		"bg-emerald-600",
		"bg-gray-600",
		"bg-green-600",
		"bg-gold-600",
		"bg-lemon-600",
		"bg-orange-600",
		"bg-pink-600",
		"bg-purple-600",
		"bg-red-600",
		"bg-rose-600",
		"bg-stone-600",
		"bg-submarine-600",
		"bg-yellow-600",
	];

	toUpperCaseColor = (color) => {
		const string = color.substring(3, color.length - 4);
		const firstLetter = string.substring(0, 1).toUpperCase();
		const others = string.substring(1);
		return firstLetter + others;
	};

	/**
	 * Inputs
	 *
	 * admins
	 * color
	 * disabled
	 * email
	 * faceitApi
	 * firstName
	 * game
	 * lastName
	 * level
	 * link
	 * name
	 * orga
	 * partner
	 * password
	 * pic
	 * socials
	 * subscription
	 * tournaments
	 * username
	 */

	inputs = (overlayArray) => {
		switch (overlayArray) {
			case "color":
				return (
					<FormSelect
						className="w-full"
						name="color"
						data={this.props.data}
						onChange={this.props.onChange}
						required
					>
						{this.colors.map((color) => (
							<option key={color + "color"} value={color}>
								{this.toUpperCaseColor(color)}
							</option>
						))}
					</FormSelect>
				);
			case "country":
				return (
					<FormCountry
						className="w-full"
						name="country"
						country={this.props.data.country}
						onChange={this.props.onChange}
					/>
				);
			case "disabled":
				return (
					<FormCheckbox
						className="w-full"
						name="disabled"
						label="Active"
						data={this.props.data}
						onChange={this.props.onChange}
					/>
				);
			case "email":
				return (
					<FormInput
						className="w-full"
						name="email"
						type="email"
						placeholder="E-Mail"
						data={this.props.data}
						onChange={this.props.onChange}
						required
					/>
				);
			case "faceitID":
				return (
					<FormInput
						className="w-full"
						name="faceitID"
						type="text"
						placeholder="Faceit ID"
						data={this.props.data}
						onChange={this.props.onChange}
					/>
				);
			case "firstName":
				return (
					<FormInput
						className="w-1/2 pr-1"
						name="firstName"
						type="text"
						placeholder="First Name"
						data={this.props.data}
						onChange={this.props.onChange}
					/>
				);

			case "game":
				return (
					<Loading
						loading={this.state.loading}
						className="w-full h-16 dark:bg-dark-400"
					>
						{!this.state.loading && (
							<FormSelect
								className="w-full"
								name="game"
								data={this.props.data}
								onChange={this.props.onChange}
							>
								{this.state.games.map((game) => (
									<option key={game._id + "game"} value={game.name}>
										{game.name}
									</option>
								))}
							</FormSelect>
						)}
					</Loading>
				);

			case "gameName":
				return (
					<FormInput
						className="w-full"
						name="gameName"
						type="text"
						placeholder="First Name"
						data={this.props.data}
						onChange={this.props.onChange}
					/>
				);

			case "lastName":
				return (
					<FormInput
						className="w-1/2 pl-1"
						name="lastName"
						type="text"
						placeholder="Last Name"
						data={this.props.data}
						onChange={this.props.onChange}
					/>
				);
			case "level":
				return (
					<FormSelect
						className="w-1/2 pr-1"
						name="level"
						data={this.props.data}
						onChange={this.props.onChange}
						disabled={this.props.data.level === "systemadmin"}
					>
						{this.context.stages.map((stage) => (
							<option
								key={stage.level + "option_Stages"}
								value={stage.stage}
							>
								{stage.name.substring(0, stage.name.length - 1)}
							</option>
						))}
					</FormSelect>
				);
			case "link":
				return (
					<FormInput
						className="w-full"
						name="link"
						type="text"
						placeholder="Link"
						data={this.props.data}
						onChange={this.props.onChange}
					/>
				);
			case "name":
				return (
					<FormInput
						className="w-full"
						name="name"
						type="text"
						placeholder="Name"
						data={this.props.data}
						onChange={this.props.onChange}
						required
					/>
				);
			case "nameLong":
				return (
					<FormInput
						className="w-full"
						name="nameLong"
						type="text"
						placeholder="Long Name"
						data={this.props.data}
						onChange={this.props.onChange}
						required
					/>
				);
			case "nameShort":
				return (
					<FormInput
						className="w-full"
						name="nameShort"
						type="text"
						placeholder="Short Name"
						data={this.props.data}
						onChange={this.props.onChange}
						required
					/>
				);
			case "orga":
				return (
					<Loading
						loading={this.state.loading}
						className="w-full h-16 dark:bg-dark-400"
					>
						{!this.state.loading && (
							<FormSelect
								className="w-full"
								name="orga"
								data={this.props.data}
								onChange={this.props.onChange}
							>
								{this.state.orgas.map((orga) => (
									<option key={orga._id + "orga"} value={orga.name}>
										{orga.name}
									</option>
								))}
							</FormSelect>
						)}
					</Loading>
				);
			case "partner":
				return (
					<FormMultiInput
						className="w-full"
						name="partner"
						data={this.props.data}
						onChange={this.props.onChange}
					/>
				);
			case "password":
				if (this.props.overlayAction === "add") {
					return (
						<FormInput
							className="w-full"
							name="password"
							type="password"
							placeholder="Password"
							data={this.props.data}
							onChange={this.props.onChange}
							required
						/>
					);
				}
				break;
			case "pic":
				return (
					<FormInput
						className="w-full"
						name="pic"
						type="text"
						placeholder="URL"
						data={this.props.data}
						onChange={this.props.onChange}
						saveURL={this.props.saveURL}
						currentAPI={this.props.currentAPI}
					/>
				);
			case "socials":
				return (
					<FormSocials
						className="w-full"
						data={this.props.data.socials}
						onChange={this.props.onChange}
					/>
				);
			case "subscription":
				return (
					<FormSelect
						className="w-1/2 pl-1"
						name="subscription"
						data={this.props.data}
						onChange={this.props.onChange}
						disabled
					>
						<option value="pro">PRO</option>
						<option value="standard">Standard</option>
					</FormSelect>
				);
			case "tournaments":
				return (
					<FormMultiInput
						className="w-full"
						name="tournaments"
						data={this.props.data}
						onChange={this.props.onChange}
					/>
				);
			case "username":
				return (
					<FormInput
						className="w-full"
						name="username"
						type="text"
						placeholder="Username"
						data={this.props.data}
						onChange={this.props.onChange}
						required
					/>
				);
			default:
				return <></>;
		}
	};

	render() {
		return (
			<form
				className="space-y-4"
				action={this.props.action}
				method={this.props.overlayAction === "add" ? "POST" : "PUT"}
				onSubmit={
					this.props.overlayAction === "add"
						? this.props.addCurrent
						: this.props.saveCurrent
				}
				autoComplete="false"
			>
				<div className="flex items-center justify-between">
					<div className="flex">
						<img
							className="h-20 border-gray-700 dark:border-white border-4 rounded-lg"
							src={
								typeof this.props.data.pic === "undefined"
									? "https://res.cloudinary.com/tmlive/image/upload/v1/user/default?_a=AJE7pWI0"
									: this.props.data.pic
							}
							alt="PIC"
							onError={(e) => {
								e.target.src =
									"https://res.cloudinary.com/tmlive/image/upload/v1/user/default?_a=AJE7pWI0";
							}}
						/>
					</div>
					<div className="text-center text-2xl font-bold dark:text-white first-letter:uppercase float-right">
						{this.props.overlayAction}
					</div>
				</div>
				<div className="flex flex-wrap space-y-4">
					{this.props.overlayArray.map((name, i) => (
						<React.Fragment key={i + "overlayArray"}>
							{this.inputs(name)}
						</React.Fragment>
					))}
				</div>
				<OverlayButtons
					marginTop="mt-12"
					closeClick={this.props.changeOverlay}
					formSubmit
				/>
			</form>
		);
	}
}
