import React from "react";
import FormLabel from "./FormLabel";

/**
 * FormSelect
 *
 * name: String
 * data: Object
 * onChange: Function (event, extra)
 * required: Boolean
 * disabled: Boolean
 * children: <>
 */
export default class FormSelect extends React.Component {
	render() {
		return (
			<div className={this.props.className}>
				<FormLabel
					name={this.props.label ? this.props.label : this.props.name}
				/>
				<select
					type="text"
					name={this.props.name}
					className={
						(this.props.disabled ? "" : "cursor-pointer ") +
						"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:text-gray-50 dark:border-gray-700 dark:bg-gray-700"
					}
					value={
						typeof this.props.data[this.props.name] === "undefined"
							? "standard"
							: this.props.data[this.props.name]
					}
					onChange={(e) => this.props.onChange(e)}
					disabled={this.props.disabled}
					required={this.props.required}
				>
					{this.props.children}
				</select>
			</div>
		);
	}
}
