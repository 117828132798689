import React, { Component } from "react";

import Button from "../../../shared/components/UIElements/Button";

/**
 * MapvetoFooter
 *
 * deleteLast: Function
 * deleteAllMaps: Function
 * disabled: Boolean
 */
export default class MapvetoFooter extends Component {
	render() {
		return (
			<div className="w-full flex justify-evenly">
				<Button
					red
					disabled={this.props.disabled}
					onClick={this.props.deleteLast.bind(this)}
				>
					Delete Last
				</Button>
				<Button
					red
					disabled={this.props.disabled}
					onClick={this.props.deleteAllMaps.bind(this)}
				>
					Delete All
				</Button>
			</div>
		);
	}
}
