import React from "react";
import { Navigate } from "react-router-dom";
import { ThemeContext } from "../shared/context/theme-context";

/**
 * Logout
 *
 * logout: Function
 */
export default class Logout extends React.Component {
	static contextType = ThemeContext;

	componentDidMount = () => {
		this.props.logout();
	};

	render() {
		return (
			<>
				{this.context.auth ? (
					<h1>
						Erfolgreich abgemeldet! Du wirst in 5 Sekunden weitergeleitet.
					</h1>
				) : (
					<Navigate to="/" replace />
				)}
			</>
		);
	}
}
