import React, { Component } from "react";
import { ReactComponent as LogoWhite } from "../../../../shared/images/LogoAlpensceneWhite.svg";
import { ReactComponent as LogoBlack } from "../../../../shared/images/LogoAlpensceneBlack.svg";

import ReactCardFlip from "react-card-flip";

/**
 * Map
 * 
 * currentMatch: Object
 * veto: Object
 */
export default class Map extends Component {
	checkBestOf = () => {
		let array = [];
		switch (this.props.currentMatch.best_of) {
			case 1:
				array = [6];
				break;
			case 2:
				array = [4, 5];
				break;
			case 3:
				array = [2, 3, 6];
				break;
			case 5:
				array = [2, 3, 4, 5, 6];
				break;
			default:
				array = [];
				break;
		}
		return array.includes(Number(this.props.veto.index));
	};
	checkBanned = () => {
		if (this.props.veto.team === 0) {
			return {
				text: "decider",
				color: "text-white",
				border: "border-white",
			};
		}
		switch (this.props.veto.picked) {
			case true:
				return {
					text: "pick",
					color: "text-lime-600",
					border: "border-lime-600",
				};

			case false:
				return {
					text: "ban",
					color: "text-red-600",
					border: "border-red-600",
				};
			default:
				return {};
		}
	};
	teamLogo = (veto) => {
		const faction = "faction" + veto.team;
		if (typeof veto.name === "undefined") {
			return "";
		}
		return this.props.currentMatch.teams[faction].avatar;
	};
	render() {
		return (
			<div className={"w-56 font-industry"}>
				<ReactCardFlip
					isFlipped={typeof this.props.veto.name === "undefined"}
					flipDirection="horizontal"
					containerClassName="h-full w-full"
				>
					<div
						className={
							"text-black rounded-lg h-full w-full border-8 " +
							this.checkBanned().border
						}
					>
						<div className="w-full flex justify-center flex-col items-center h-44 relative">
							<div className="absolute z-20 w-full flex justify-center items-center flex-col bottom-0">
								{this.props.veto.team !== 0 &&
									typeof this.props.veto.name !== "undefined" && (
										<img
											className="rounded-full w-24 mb-3"
											src={this.teamLogo(this.props.veto)}
											alt="TeamLogo"
										/>
									)}

								<div
									style={{
										textShadow: `0 0 5px ${
											this.props.veto.picked ? "#000" : "#fff"
										}`,
									}}
									className={
										this.checkBanned().color +
										" h-12 flex justify-center items-center uppercase text-3xl tracking-widest"
									}
								>
									{this.checkBanned().text}
								</div>
							</div>
							<div className="absolute z-10 h-full w-full">
								{typeof this.props.veto.name !== "undefined" && (
									<img
										className="object-cover h-full w-full absolute"
										src={require(`../../../../shared/images/maps/${this.props.veto.name}.png`)}
										alt={""}
									/>
								)}
								<div
									className={
										(this.props.veto.picked
											? "bg-black bg-opacity-40"
											: "bg-white bg-opacity-40") +
										" absolute w-full h-full"
									}
								></div>
							</div>
						</div>
						<div className="uppercase text-3xl w-full justify-center h-12 flex items-center bg-white pt-1.5">
							{this.props.veto.name}
						</div>
					</div>
					<div
						className={
							"h-full w-full flex items-center justify-center rounded-lg " +
							(this.checkBestOf() ? "bg-white" : "bg-tertiarty")
						}
					>
						{this.checkBestOf() && <LogoBlack className="w-36" />}
						{!this.checkBestOf() && <LogoWhite className="w-36" />}
					</div>
				</ReactCardFlip>
			</div>
		);
	}
}
