import React, { Component } from "react";
import Button from "../../../shared/components/UIElements/Button";
import FormInput from "../../../shared/components/UIElements/Forms/FormInput";

/**
 * Participant
 *
 * className: String
 * participant: Object
 * saveAllParticipants: Function
 * deleteParticipant: Function
 * indexSelected: Number
 * changeIndexSelected: Function
 */
export default class Participant extends Component {
	state = {
		name: "",
		url: "",
		social: "",
		live: false,
		index: -1,
		edit: false,
	};

	handleInputChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({ [name]: value });
	};

	changeLive = (live) => {
		this.props.changeIndexSelected(-1);
		this.setState({ live: !live, edit: false });
		let newState = this.state;
		newState.live = !live;
		this.props.saveAllParticipants(newState);
	};

	edit = () => {
		this.props.changeIndexSelected(this.state.index);
		this.setState({ edit: true });
	};

	save = () => {
		this.setState({ edit: false });
		this.props.saveAllParticipants(this.state);
	};

	componentDidMount = () => {
		this.setState({
			name: this.props.participant.name,
			url: this.props.participant.url,
			social: this.props.participant.social,
			live: this.props.participant.live,
			index: this.props.participant.index,
		});
	};

	componentDidUpdate = (oldProps) => {
		if (oldProps.participant !== this.props.participant) {
			this.componentDidMount();
		}
	};

	render() {
		return (
			<div className={this.props.className + " flex space-y-2 flex-col"}>
				<FormInput
					name="name"
					type="text"
					placeholder="Name"
					onChange={this.handleInputChange}
					data={this.state}
					required={true}
					disabled={!this.state.edit}
				/>
				<FormInput
					name="url"
					type="text"
					placeholder="Facecam URL"
					onChange={this.handleInputChange}
					data={this.state}
					disabled={!this.state.edit}
				/>
				<FormInput
					name="social"
					type="text"
					placeholder="Socials"
					onChange={this.handleInputChange}
					data={this.state}
					disabled={!this.state.edit}
				/>
				<div className="flex gap-1 pt-2">
					<>
						{!this.state.edit && (
							<Button
								width="w-1/3"
								smallPadding
								onClick={this.edit.bind(this)}
								disabled={
									this.props.indexSelected > -1 &&
									this.props.indexSelected !==
										this.props.participant.index
								}
							>
								Edit
							</Button>
						)}
						{this.state.edit && (
							<Button
								width="w-1/3"
								smallPadding
								green
								onClick={this.save.bind(this)}
							>
								Save
							</Button>
						)}
					</>
					<Button
						smallPadding
						width="w-1/3"
						onClick={() => this.changeLive(this.state.live)}
						red={!this.state.live}
						green={this.state.live}
						disabled={!this.state.edit}
					>
						<div className="uppercase">
							{this.state.live ? "Live" : "Set Live"}
						</div>
					</Button>
					<Button
						smallPadding
						width="w-1/3"
						onClick={() => {
							this.setState({ edit: false });
							this.props.deleteParticipant(this.state.index);
						}}
						red
						disabled={!this.state.edit}
					>
						Delete
					</Button>
				</div>
			</div>
		);
	}
}
