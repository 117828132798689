import React, { Component } from "react";

/**
 * FinalVote
 *
 * className: String
 * mapveto: Array
 * loadPic: Function
 */
export default class FinalVote extends Component {
	render() {
		return (
			<div className={this.props.className}>
				<div className="w-full text-center text-xl h-8">Votes</div>
				{this.props.mapveto.length === 0 && (
					<div className="text-gray-400 text-center">No votes</div>
				)}
				{this.props.mapveto.length !== 0 && (
					<div className="flex flex-col space-y-2">
						{this.props.mapveto
							.sort((a, b) => a.index - b.index)
							.map((map) => (
								<div
									key={map.name + "left"}
									className="grid content-between col-span-2 relative text-white h-32"
								>
									<div className="absolute h-full">
										<img
											className="object-cover h-full"
											src={this.props.loadPic("maps", map.name)}
											alt={map.name}
										/>
									</div>
									{map.team !== 0 && (
										<div className="h-1/2 absolute top-0 w-full flex items-center">
											{`Team${map.team}`}
										</div>
									)}
									<div
										className={
											(map.team === 0 ? "h-full" : "h-1/2") +
											" absolute bottom-0 w-full flex items-center"
										}
									>
										<div className="w-1/2">
											{map.team === 0
												? "Decider"
												: map.picked
												? "PICKED"
												: "BANNED"}
										</div>
										<div className="w-1/2"></div>
									</div>
								</div>
							))}
					</div>
				)}
			</div>
		);
	}
}
