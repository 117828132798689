import React, { Component } from "react";

import Header from "../Header";
import MapvetoFooter from "./MapvetoFooter";
import FinalVote from "./FinalVote";
import AllMaps from "./AllMaps";

/**
 * Mapveto
 *
 * currentStream: String
 * changeStream: Function
 * loadPic: Function
 * socket: Object
 * mapveto: Array
 * changeMapveto: Function(action, veto)
 */
export default class Mapveto extends Component {
	state = {
		name: "",
		team: 0,
	};

	// Presafe Team pick or Decider
	saveTeam = (map, team) => {
		if (team === 0) {
			const newIndex = this.props.mapveto.length;
			const newMapveto = {
				index: newIndex,
				team: 0,
				name: map,
				picked: true,
			};
			this.props.changeMapveto("add", newMapveto);
			return;
		}
		this.setState({ name: map, team: team });
	};

	// Save Final Pick
	savePick = (picked) => {
		const currentMap = this.state;
		const newIndex = this.props.mapveto.length;
		const newMapveto = {
			index: newIndex,
			picked: picked,
			team: currentMap.team,
			name: currentMap.name,
		};
		this.props.changeMapveto("add", newMapveto);
	};

	// Delete Last Pick
	deleteLast = () => {
		this.setState({ name: "", team: 0 });
		this.props.changeMapveto("deleteLast");
	};

	// delete All Picks
	deleteAllMaps = () => {
		this.setState({ name: "", team: 0 });
		this.props.changeMapveto("deleteAllMaps");
	};

	render() {
		return (
			<>
				<Header headline="Mapveto" subline="Team 1 vs. Team 2" />
				<div className="flex">
					<FinalVote
						className="w-1/3"
						mapveto={this.props.mapveto}
						loadPic={this.props.loadPic}
					/>
					<AllMaps
						className="w-2/3 pl-8"
						loadPic={this.props.loadPic}
						saveTeam={this.saveTeam}
						savePick={this.savePick}
						currentMap={this.state}
						mapveto={this.props.mapveto}
					/>
				</div>
				<MapvetoFooter
					deleteLast={this.deleteLast}
					deleteAllMaps={this.deleteAllMaps}
					disabled={this.props.mapveto.length === 0}
				/>
			</>
		);
	}
}
