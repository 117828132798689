import React from "react";

import FormInput from "./FormInput";
import FormPartner from "./FormPartner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import FormLabel from "./FormLabel";

/**
 * FormMultiInput
 *
 * className: String
 * name: String
 * data: Object
 * onChange: Function (event, extra)
 */
export default class FormMultiInput extends React.Component {
	state = {
		partner: [],
		tournaments: [],
	};

	// Array Change on this Component
	changeArrays = (event, name, index) => {
		const targetName = event.target.name;
		const targetValue = event.target.value;

		let array = this.state[name];
		const object = { ...array[index], [targetName]: targetValue };
		
		array.splice(index, 1, object);
		this.setState({ [name]: array });
	};

	// Actions to edit array counts
	actions = (name, action, index) => {
		switch (action) {
			case "add":
				let object;
				if (name === "socials") {
					object = { name: "", pic: "" };
				} else if (name === "tournaments") {
					object = { name: "" };
				}
				if (this.state[name].length === 0) {
					this.setState({
						[name]: [object],
					});
				} else {
					this.setState({
						[name]: [...this.state[name], object],
					});
				}
				break;

			case "remove":
				let array = this.state[name];
				array.splice(index, 1);
				this.setState({ [name]: array });
				break;

			default:
				break;
		}
	};

	componentDidMount = () => {
		if (this.props.name === "partner") {
			if (this.props.data.partner) {
				this.setState({ partner: this.props.data.partner });
			}
			if (this.props.data.tournaments) {
				this.setState({ tournaments: this.props.data.tournaments });
			}
		}
	};

	render() {
		return (
			<div className={"flex flex-col space-y-2 " + this.props.className}>
				<FormLabel name={this.props.name} />

				{this.props.name === "partner" && (
					<>
						<button
							className="w-full border-2 border-dashed rounded-md h-12"
							onClick={(e) => {
								e.preventDefault();
								this.actions("partner", "add");
							}}
						>
							<FontAwesomeIcon
								icon={faCirclePlus}
								size="xl"
								className="text-gray-400"
							/>
						</button>
						{this.state.partner.length > 0 &&
							this.state.partner.map((entry, i) => (
								<div
									key={entry.name + i}
									className="grid grid-cols-12 grid-rows-2 gap-3 border-2 rounded-md p-2 border-gray-600 dark:border-gray-50"
								>
									<FormPartner
										index={i}
										entry={entry}
										changeArrays={this.changeArrays}
										actions={this.actions}
									/>
								</div>
							))}
					</>
				)}
				{this.props.name === "tournaments" && (
					<>
						<button
							className="w-full border-2 border-dashed rounded-md h-12 order-0"
							onClick={(e) => {
								e.preventDefault();
								this.actions("tournaments", "add");
							}}
						>
							<FontAwesomeIcon
								icon={faCirclePlus}
								size="xl"
								className="text-gray-400"
							/>
						</button>
						{this.state.tournaments.length > 0 &&
							this.state.tournaments.map((entry, i) => (
								<div
									className="flex"
									key={`${this.props.data._id}-${entry.name}-entry-${i}`}
								>
									<FormInput
										className="w-full"
										type="text"
										name={entry.name}
										placeholder="Name"
										onChange={this.props.onChange}
										addon="tournaments"
									/>
								</div>
							))}
					</>
				)}
			</div>
		);
	}
}
