import React from "react";

import Flag from "react-world-flags";

/**
 * DashboardList
 *
 * entry: Object
 * tableSchema: Array
 * index: Number
 * changeOverlay: Function (selectedData, action)
 */
export default class ListTableBody extends React.Component {
	TrueSystemAdmin = this.props.entry.level === "systemadmin";

	render() {
		return (
			<tr className="dark:bg-gray-800 dark:border-gray-700 odd:bg-gray-100 even:bg-gray-200 odd:dark:bg-gray-800 even:dark:bg-gray-700 group">
				<td className="px-5 py-2 lg:py-4 hidden sm:table-cell relative group-last:rounded-bl-lg">
					<img
						className="h-10 rounded-lg m-0"
						src={this.props.entry.pic}
						alt="Pic"
					/>
					<Flag
						className="bg-black h-4 absolute top-2 left-12 lg:top-3"
						code={this.props.entry.country}
					/>
				</td>
				{this.props.tableSchema.map((entry, i) => (
					<td
						className={
							"p-1 sm:px-5 sm:py-2 lg:py-4 " +
							(entry.sort === "username" ? "font-semibold " : "") +
							(entry.xlHide
								? "hidden xl:table-cell"
								: entry.lgHide
								? "lg:table-cell hidden"
								: "")
						}
						key={i + "-schemaBody"}
					>
						{typeof entry.data === "undefined"
							? ""
							: (entry.sort === "email" ||
									entry.sort === "firstName" ||
									entry.sort === "lastName") &&
							  entry.data.length > 15
							? entry.data.substring(0, 15) + "..."
							: entry.data}
					</td>
				))}
				<td className="p-1 sm:px-5 sm:py-2 lg:py-4 text-center relative table-cell group-last:rounded-br-lg">
					<div className="flex flex-col">
						<button
							className={
								"font-medium " +
								(this.TrueSystemAdmin
									? "text-gray-300 drop-shadow-md dark:opacity-50"
									: "text-primary dark:text-secondary hover:underline")
							}
							onClick={() =>
								this.props.changeOverlay(this.props.entry, "edit")
							}
							disabled={this.TrueSystemAdmin}
						>
							Edit
						</button>
						<button
							className={
								"font-medium " +
								(this.TrueSystemAdmin
									? "text-gray-300 drop-shadow-md dark:opacity-50"
									: "text-primary dark:text-secondary hover:underline")
							}
							onClick={() =>
								this.props.changeOverlay(this.props.entry, "delete")
							}
							disabled={this.TrueSystemAdmin}
						>
							Delete
						</button>
					</div>
				</td>
			</tr>
		);
	}
}
