import React from "react";

import { ThemeContext } from "../../shared/context/theme-context.js";
import socketIOClient from "socket.io-client";
import Mapveto from "./Mapveto/Mapveto";
import Matches from "./Matches/Matches";
import { Routes, Route, NavLink, Navigate } from "react-router-dom";
import Home from "./Home/Home.jsx";
import Participants from "./Participants/Participants.jsx";
import Live from "./Live/Live.jsx";
import Loading from "../../shared/components/UIElements/Loading.jsx";

export default class MyStream extends React.Component {
	static contextType = ThemeContext;
	socket = {};

	state = {
		currentStream: "",
		oldStream: "",
		currentOrganizer: "",
		currentTournament: "",
		currentMatch: {},
		currentView: "",
		time: "",
		tournamentInfos: {},
		tournaments: [],
		mapveto: [],
		matches: [],
		participants: [],
		visual: [],
		loadTournament: {
			offset: 0,
			limit: 100,
		},
		loadMatches: {
			offset: 0,
			limit: 100,
		},
		schedulePage: 1,
		scheduleRound: 1,
		bracket: [],
	};

	// Helper loading pics
	loadPic = (folder, name) => {
		const pic = require(`../../shared/images/${folder}/${name}.png`);
		return pic;
	};

	// Handle Input Change from Component "Home":
	// currentStream, currentOrganizer, currentTournament
	handleInputChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({ [name]: value });

		if (value === "") {
			this.socket.emit(
				"changeOrganizer",
				this.state.currentStream,
				{ value: "" },
				(data) => {
					this.setStream(data);
				}
			);
			return;
		}

		switch (name) {
			case "currentStream":
				const data = {
					old: this.state.oldStream,
					new: value,
				};
				this.changeStream(data);
				break;
			case "currentOrganizer":
				this.changeOrganizer(value);
				break;
			case "currentTournament":
				this.socket.emit(
					"changeTournament",
					this.state.currentStream,
					value,
					this.state.loadMatches,
					(stream) => {
						this.setStream(stream);
					}
				);
				break;
			default:
				break;
		}
	};

	// Socket emitter on Organizer Change
	changeOrganizer = (value) => {
		const data = {
			query: this.state.loadTournament,
			value: value,
		};
		this.socket.emit(
			"changeOrganizer",
			this.state.currentStream,
			data,
			(resp) => {
				this.setStream(resp);
			}
		);
	};

	// Socket emitter on Stream Change
	changeStream = (data) => {
		this.socket.emit("changeStream", data, (resp) => {
			this.setStream(resp);
			// if (typeof data.old === "undefined") {
			// 	this.changeOrganizer(cb.organizerID);
			// }
		});
	};

	// Helper for Functions
	setStream = (data) => {
		this.setState({
			currentStream: data.stream,
			oldStream: data.stream,
			mapveto: data.veto,
			matches: data.matches,
			participants: data.participants,
			currentTournament: data.tournamentID,
			currentMatch: data.currentMatch,
			currentView: data.view,
			currentOrganizer: data.organizerID,
			tournamentInfos: data.tournamentInfos,
			time: data.time,
			tournaments: data.tournaments,
			visual: data.visual,
			bracket: data.bracket,
		});
	};

	// Change Mapveto Switcher
	changeMapveto = (action, veto) => {
		switch (action) {
			case "add":
				this.socket.emit("addMap", this.state.currentStream, veto);
				this.setState({ mapveto: [...this.state.mapveto, veto] });
				return;
			case "deleteLast":
				this.socket.emit(
					"deleteLastMap",
					this.state.currentStream,
					(mapveto) => {
						this.setState({ mapveto: mapveto });
					}
				);
				return;
			case "deleteAllMaps":
				this.socket.emit("deleteAllMaps", this.state.currentStream);
				this.setState({ mapveto: [] });
				return;
			default:
				break;
		}
		this.setState({ mapveto: veto });
	};

	// Socket emitter on Current Map Change
	changeCurrentMatch = (match) => {
		this.socket.emit(
			"changeMatch",
			this.state.currentStream,
			match,
			(newMatch) => {
				this.setState({ currentMatch: newMatch, mapveto: [] });
			}
		);
	};

	// Socket emitter for:
	// View, Participant
	handleChangeToSocket = (action, stateChange, data) => {
		this.socket.emit(action, this.state.currentStream, data, (newData) => {
			this.setState({ [stateChange]: newData });
		});
	};

	changeQuery = (value) => {
		let final = this.state.loadTournament.offset;
		if (value > 0) {
			final = final + 100;
		} else if (value < 0) {
			final = final - 100;
		}
		const data = { offset: final, limit: 100 };
		this.setState({ loadTournament: data });
		this.socket.emit(
			"changeOrganizer",
			this.state.currentStream,
			{ value: this.state.currentOrganizer, query: data },
			(resp) => {
				this.setStream(resp);
			}
		);
	};

	changeTime = (state) => {
		const time = Math.round(
			new Date(
				state.year,
				state.month,
				state.day,
				state.hours,
				state.minutes,
				state.seconds
			).getTime() / 1000
		);
		this.setState({ time: time });
		this.socket.emit("changeTime", this.state.currentStream, time);
	};

	changeVisual = (name) => {
		let visuals = [...this.state.visual];
		if (visuals.includes(name)) {
			visuals = visuals.filter((v) => v !== name);
		} else {
			visuals.push(name);
		}
		this.setState({ visual: visuals });
		this.socket.emit("changeVisual", this.state.currentStream, visuals);
	};

	reloadTournament = () => {
		this.socket.emit(
			"changeTournament",
			this.state.currentStream,
			this.state.currentTournament,
			this.state.loadMatches,
			(stream) => {
				this.setStream(stream);
			}
		);
	};

	changeBracket = (bracket) => {
		this.setState({ bracket: bracket });
		this.socket.emit("changeBracket", this.state.currentStream, bracket);
	};

	changeSchedule = (schedule) => {
		this.setState({
			schedulePage: schedule.schedulePage,
			scheduleRound: schedule.scheduleRound,
		});
		this.socket.emit("changeSchedule", this.state.currentStream, schedule);
	};

	componentDidMount = () => {
		this.socket = socketIOClient(this.context.server);

		this.changeStream({ new: "alpenSceneTV" });

		this.socket.on("initiallyConnected", () => {
			this.setState({ currentStream: "" });
		});
		this.socket.on("changeTournament", (stream) => {
			this.setStream(stream);
		});
		this.socket.on("changeMapveto", (mapveto) => {
			this.setState({ mapveto: mapveto });
		});
		this.socket.on("changeMatch", (match) => {
			this.setState({ currentMatch: match, mapveto: [] });
		});
		this.socket.on("changeView", (view) => {
			this.setState({ currentView: view });
		});
		this.socket.on("changeParticipants", (participants) => {
			this.setState({ participants: participants });
		});
		this.socket.on("changeOrganizer", (data) => {
			this.setStream(data);
		});
		this.socket.on("changeTime", (data) => {
			this.setState({ time: data });
		});
		this.socket.on("changeVisual", (visual) => {
			this.setState({ visual: visual });
		});
		this.socket.on("changeSchedule", (schedule) => {
			this.setState({
				schedulePage: schedule.page,
				scheduleRound: schedule.round,
			});
		});
		this.socket.on("changeBracket", (bracket) => {
			this.setState({ bracket: bracket });
		});
	};
	componentWillUnmount = () => {
		this.socket.off("initiallyConnected ");
		this.socket.off("changeTournament");
		this.socket.off("changeMapveto");
		this.socket.off("changeMatch");
		this.socket.off("changeView");
		this.socket.off("changeParticipants");
		this.socket.off("changeOrganizer");
		this.socket.off("changeTime");
		this.socket.off("changeVisual");
		this.socket.off("changeSchedule");
		this.socket.off("changeBracket");
	};

	links = ["home", "participants", "matches", "mapveto", "live"];

	render() {
		return (
			<Loading loading={this.state.currentStream === ""}>
				{this.state.currentStream !== "" && (
					<>
						<div className="w-full grid gap-3 grid-flow-col text-center mb-4">
							{this.links.map((link, i) => (
								<NavLink
									key={i + "-link-header"}
									className={(isActive) =>
										(isActive.isActive
											? "bg-primary text-white border"
											: "dark:border-white border-tertiarty border") +
										" py-4 rounded-lg" +
										(this.state.currentTournament === "" &&
										this.state.currentOrganizer !== "offline"
											? " cursor-not-allowed"
											: "")
									}
									to={"./" + (link === "home" ? "" : link)}
									onClick={(e) => {
										if (
											this.state.currentTournament === "" &&
											this.state.currentOrganizer !== "offline"
										) {
											e.preventDefault();
										}
									}}
								>
									<p className="first-letter:uppercase">{link}</p>
								</NavLink>
							))}
						</div>
						<Routes>
							<Route
								path=""
								element={
									<Home
										state={this.state}
										handleInputChange={this.handleInputChange}
										tournaments={this.state.tournaments}
										changeQuery={this.changeQuery}
										reload={this.reloadTournament}
									/>
								}
							/>
							{(this.state.currentTournament !== "" ||
								this.state.currentOrganizer === "offline") && (
								<>
									<Route
										path="/participants"
										element={
											<Participants
												participants={this.state.participants}
												saveData={(data) =>
													this.handleChangeToSocket(
														"changeParticipants",
														"participants",
														data
													)
												}
											/>
										}
									/>
									<Route
										path="/matches"
										element={
											<Matches
												matches={this.state.matches}
												currentMatch={this.state.currentMatch}
												changeCurrentMatch={this.changeCurrentMatch}
												changeBracket={this.changeBracket}
												bracket={this.state.bracket}
											/>
										}
									/>
									<Route
										path="/mapveto"
										element={
											<Mapveto
												currentStream={this.state.currentStream}
												changeStream={this.changeStream}
												loadPic={this.loadPic}
												socket={this.socket}
												mapveto={this.state.mapveto}
												changeMapveto={this.changeMapveto}
											/>
										}
									/>
									<Route
										path="/live"
										element={
											<Live
												state={this.state}
												changeTime={this.changeTime}
												changeView={(data) =>
													this.handleChangeToSocket(
														"changeView",
														"currentView",
														data
													)
												}
												changeVisual={this.changeVisual}
												schedulePage={this.state.schedulePage}
												scheduleRound={this.state.scheduleRound}
												changeSchedule={this.changeSchedule}
											/>
										}
									/>
								</>
							)}
							<Route path="*" element={<Navigate to="./" />} />
						</Routes>
					</>
				)}
			</Loading>
		);
	}
}
