import React from "react";
import Loading from "../../shared/components/UIElements/Loading";

import PicTextCard from "../../shared/components/Cards/PicTextCard";
import AddCard from "../../shared/components/Cards/AddCard";

/**
 * AllGames
 *
 * dbActions: Function (method, apiURL)
 * state: Object
 * changeCurrentAPI: Function (propsAPI)
 * changeOverlay: Function (selectedData, action)
 * overlayArray: Array
 * changeCurrentOverlayArray: Function (propsOverlayArray)
 * api: String
 * reload: Boolean
 */
export default class AllGames extends React.Component {
	state = {
		loading: true,
	};

	// Set loading to true and load from DB
	loadDB = () => {
		this.props.changeCurrentAPI(this.props.api);
		this.props.changeCurrentOverlayArray(this.props.overlayArray);
		this.setState(
			{ loading: true },
			this.props.dbActions("GET", this.props.api, () =>
				this.setState({ loading: false })
			)
		);
	};

	componentDidMount = () => {
		this.loadDB();
	};

	// Check if data should update
	componentDidUpdate = (oldProps) => {
		if (oldProps.state.selectedData !== this.props.state.selectedData) {
			return;
		}
		if (!this.state.loading && this.props.state !== oldProps.state) {
			this.loadDB();
		}
	};

	sortData = (data) => {
		data.sort((a, b) => {
			var nameA = a.name.toUpperCase();
			var nameB = b.name.toUpperCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		});
	};

	myData = []
		.concat(this.props.state.data)
		.sort((a, b) => (a.name > b.name ? 1 : -1))
		.map((game) => (
			<PicTextCard
				data={game}
				key={game._id}
				changeOverlay={this.props.changeOverlay}
			/>
		));

	render() {
		return (
			<Loading loading={this.state.loading}>
				{!this.state.loading && (
					<div className="grid grid-cols-12 px-18 gap-5">
						{this.props.state.data
							.sort((a, b) => (a.name > b.name ? 1 : -1))
							.map((game) => (
								<PicTextCard
									data={game}
									key={game._id}
									changeOverlay={this.props.changeOverlay}
								/>
							))}
						<AddCard changeOverlay={this.props.changeOverlay} />
					</div>
				)}
			</Loading>
		);
	}
}
