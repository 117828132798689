import React, { Component } from "react";
import Map from "./Map";

/**
 * Mapveto
 * 
 * currentMatch: Object
 * veto: Object
 */
export default class Mapveto extends Component {
	dummy = () => {
		let array = [];
		const veto = this.props.veto;
		for (let i = 0; i < 7; i++) {
			if (veto[i] !== undefined) {
				array.push(veto[i]);
			} else {
				array.push({ index: i });
			}
		}
		return array;
	};
	render() {
		return (
			<>
				{this.dummy().map((veto, i) => (
					<Map
						key={i + "-mapveto"}
						veto={veto}
						currentMatch={this.props.currentMatch}
					/>
				))}
			</>
		);
	}
}
