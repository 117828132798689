import React, { Component } from "react";
import FormInput from "./FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import {
	faTwitter,
	faSteam,
	faDiscord,
	faTwitch,
} from "@fortawesome/free-brands-svg-icons";
import FormLabel from "./FormLabel";

/**
 * FormSocials
 * 
 * data: Object
 * className: String
 * onChange: Function
 */
export default class FormSocials extends Component {
	socials = [
		{ name: "website", icon: faHouse },
		{ name: "twitter", icon: faTwitter },
		{ name: "steam", icon: faSteam },
		{ name: "discord", icon: faDiscord },
		{ name: "twitch", icon: faTwitch },
	];

	// Placeholder for socials
	placeHolder = (entry) => {
		switch (entry.name) {
			case "steam":
				return "SteamID";
			case "website":
			case "twitch":
				return "URL";
			default:
				return "Username";
		}
	};

	// Get Correct Form
	transferToDataFormat = (index) => {
		let socials = [];
		this.socials.forEach((el) => {
			socials.push(el);
		});
		if (index === "missing") {
			let newArray = [];
			if (this.props.data) {
				this.props.data.forEach((el) =>
					newArray.push(el.name)
				);
			}
			const filtered = socials.filter((el) => !newArray.includes(el.name));
			return filtered;
		} else if (index === "string") {
			let newArray = [];
			socials.forEach((el) => {
				newArray.push(el.name);
			});
			return newArray;
		}
	};

	// Sort Function
	sortArray = (entryName) => {
		return this.transferToDataFormat("string").indexOf(entryName) + 1;
	};

	render() {
		return (
			<div className={"flex flex-col space-y-2 " + this.props.className}>
				<FormLabel name="Socials" />
				{this.props.data &&
					this.props.data.map((entry, i) => (
						<div
							className={"order-" + this.sortArray(entry.name) + " flex"}
							key={`${this.props.data._id}-${this.sortArray(
								entry.name
							)}-entry-${i}`}
						>
							<FormInput
								className="w-full"
								type="text"
								name={entry.name}
								placeholder={this.placeHolder(entry)}
								onChange={this.props.onChange}
								username={entry.username}
								addon="socials"
							/>
						</div>
					))}
				<div className="order-last flex-wrap">
					{this.transferToDataFormat("missing").map((entry) => (
						<button
							onClick={(e) => {
								e.preventDefault();
								this.props.onChange({ target: entry }, "socials");
							}}
							key={entry.name + "icon"}
							className="h-10 mr-2 mb-2 w-10 inline-flex items-center justify-center text-sm text-gray-900 bg-gray-200 border border-gray-300 rounded-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600"
						>
							<FontAwesomeIcon icon={entry.icon} />
						</button>
					))}
				</div>
			</div>
		);
	}
}
