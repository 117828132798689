import React from "react";
import Loading from "../../shared/components/UIElements/Loading";

import DashboardList from "./DashboardList";

/**
 * AllDataList
 *
 * dbActions: Function (method, apiURL)
 * state: Object
 * changeCurrentAPI: Function (propsAPI)
 * changeOverlay: Function (selectedData, action)
 * overlayArray: Array
 * changeCurrentOverlayArray: Function (propsOverlayArray)
 * setQuery: Function (limit, page, sort, filter)
 * deleteFilter: Function
 * api: String
 * reload: Boolean
 */
export default class AllDataList extends React.Component {
	state = {
		loading: true,
		defaultLimit: 10,
		limitOptions: [1, 5, 10, 20, 30],
	};

	// Set loading to true and load from DB
	loadDB = () => {
		this.props.changeCurrentAPI(this.props.api);
		this.props.changeCurrentOverlayArray(this.props.overlayArray);
		this.setState(
			{ loading: true },
			this.props.dbActions("GET", this.props.api, () =>
				this.setState({ loading: false })
			)
		);
	};

	// Define table Schema and generate Header
	exampleSchema = Object;
	tableSchema = (data) => {
		let array;
		switch (this.props.api) {
			case "user/manager":
				array = [
					{ head: "Username", sort: "username", data: data.username },
					{ head: "Level", sort: "level", data: data.level },
					{ head: "Sub", sort: "subscription", data: data.subscription },
					{ head: "Email", sort: "email", data: data.email, lgHide: true },
					{
						head: "First Name",
						sort: "firstName",
						data: data.firstName,
						xlHide: true,
					},
					{
						head: "Last Name",
						sort: "lastName",
						data: data.lastName,
						xlHide: true,
					},
				];
				break;
			case "db/players":
				array = [
					{ head: "Nickname", sort: "gameName", data: data.gameName },
					{
						head: "First Name",
						sort: "firstName",
						data: data.firstName,
						lgHide: true,
					},
					{
						head: "Last Name",
						sort: "lastName",
						data: data.lastName,
						lgHide: true,
					},
				];
				break;
			default:
				array = [
					{ head: "Long Name", sort: "nameLong", data: data.nameLong },
					{
						head: "Short Name",
						sort: "nameShort",
						data: data.nameShort,
						xlHide: true,
					},
				];
				break;
		}
		if (Object.entries(this.exampleSchema).length === 0) {
			for (const object of array) {
				this.exampleSchema[object.head] = "";
			}
		}
		return array;
	};

	componentDidMount = () => {
		this.loadDB();
	};

	// Check if data should update
	componentDidUpdate = (oldProps) => {
		if (oldProps.state.selectedData !== this.props.state.selectedData) {
			return;
		}
		if (!this.state.loading && this.props.state !== oldProps.state) {
			this.loadDB();
		}
	};

	render() {
		return (
			<Loading loading={this.state.loading}>
				{!this.state.loading && (
					<DashboardList
						state={this.props.state}
						changeOverlay={this.props.changeOverlay}
						setQuery={this.props.setQuery}
						defaultLimit={this.state.defaultLimit}
						limitOptions={this.state.limitOptions}
						tableSchema={this.tableSchema}
						exampleSchema={this.exampleSchema}
						deleteFilter={this.props.deleteFilter}
					/>
				)}
			</Loading>
		);
	}
}
