import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

/**
 * PicTextCard
 *
 * data: Object
 * changeOverlay: Function (selectedData, action)
 */
export default class PicTextCard extends React.Component {
	render() {
		return (
			<div className="relative col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
				<button
					className={
						(this.props.data.disabled ? "opacity-30 " : "") +
						" rounded-xl text-left w-full " +
						this.props.data.color
					}
					onClick={() => this.props.changeOverlay(this.props.data, "edit")}
				>
					<img
						src={this.props.data.pic}
						className="rounded-t-xl object-cover h-24 w-full"
						alt={this.props.data.name}
					/>
					<div className="text-lg text-gray-50 p-3 xl:text-xl">
						<p>
							{this.props.data.name}
							{this.props.data.orga ? ` (${this.props.data.orga})` : ""}
						</p>
					</div>
				</button>
				<button
					className={
						(this.props.data.disabled
							? "text-gray-900 dark:text-gray-50 "
							: "") +
						"absolute top-2 right-3 text-gray-50 hover:!text-primary"
					}
					onClick={() =>
						this.props.changeOverlay(this.props.data, "delete")
					}
				>
					<FontAwesomeIcon icon={faClose} />
				</button>
			</div>
		);
	}
}
