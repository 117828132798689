import React, { Component } from "react";
import Button from "../../../shared/components/UIElements/Button";
import FormSelect from "../../../shared/components/UIElements/Forms/FormSelect";

export default class SetTime extends Component {
	state = {
		day: 0,
		month: 0,
		year: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	};

	currentDate = new Date();

	indexTime = {
		day: { n: 31, zero: false },
		month: { n: 12, zero: false },
		year: { n: this.currentDate.getFullYear() + 10, zero: false },
		hours: { n: 24, zero: true },
		minutes: { n: 60, zero: true },
		seconds: { n: 60, zero: true },
	};

	handleInputChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({ [name]: value });
	};

	timeData = (data) => {
		const time = this.indexTime[data];
		const array = [];
		let start = 0;
		if (data === "year") {
			start = time.n - 20;
		}
		for (let i = start; i < time.n; i++) {
			const zero = time.zero ? i : i + 1;
			array.push(zero);
		}
		return array;
	};

	componentDidUpdate = (oldProps) => {
		if (oldProps !== this.props) {
			this.loadState();
		}
	};
	componentDidMount = () => {
		this.loadState();
	};

	loadState = () => {
		const newDate = new Date(this.props.time * 1000);
		this.setState({
			day: newDate.getDate(),
			month: newDate.getMonth(),
			year: newDate.getFullYear(),
			hours: newDate.getHours(),
			minutes: newDate.getMinutes(),
			seconds: newDate.getSeconds(),
		});
	};

	render() {
		return (
			<div className="gap-4 grid grid-cols-3">
				{Object.keys(this.indexTime).map((key, i) => (
					<FormSelect
						key={i + "-liveIn"}
						onChange={this.handleInputChange}
						data={this.state}
						name={key}
					>
						{this.timeData(key).map((number, i) => (
							<option key={i + "-timeData"} value={number}>
								{number}
							</option>
						))}
					</FormSelect>
				))}

				<Button onClick={() => this.props.changeTime(this.state)} green>
					Set Time
				</Button>
			</div>
		);
	}
}
