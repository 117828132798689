import React, { Component } from "react";
import { useParams } from "react-router-dom";
import Login from "./Login";

/**
 * ForgotWrapper
 * 
 * login: Function
 * resetPW: Boolean
 */
export default class ForgotWrapper extends Component {
	shouldComponentUpdate = () => {
		return false;
	};
	render() {
		const Wrapper = () => {
			const params = useParams();
			return (
				<Login
					params={params}
					login={this.props.login}
					resetPW={this.props.resetPW}
				/>
			);
		};
		return <Wrapper />;
	}
}
