import React, { Component } from "react";

/**
 * Section
 * 
 * even: Boolean
 * text: String
 * image: String
 * alt: String
 */
export default class Section extends Component {
	render() {
		return (
			<>
				<div className="flex flex-col lg:flex-row items-center w-full lg:h-96">
					<div
						className={
							"w-full lg:w-1/2 h-auto lg:h-96 flex justify-center items-center text-3xl md:text-6xl text-center p-4 order-1 lg:order-" +
							(this.props.even ? "1" : "2")
						}
					>
						{this.props.text}
					</div>
					<div
						className={
							"h-auto md:h-full w-full lg:w-1/2 flex justify-center p-12 order-2 lg:order-" +
							(this.props.even ? "2" : "1")
						}
					>
						<img
							src={this.props.image}
							alt={this.props.alt}
							className="h-auto md:h-full rounded-xl object-cover"
						/>
					</div>
				</div>
			</>
		);
	}
}
