import React from "react";

import OverlayEdit from "./OverlayEdit";
import OverlayDelete from "./OverlayDelete";

/**
 * OverlayContainer
 *
 * selectedData: Object
 * saveURL: Function ()
 * changeOverlay: Function (currentData)
 * overlayArray: Array
 * onChange: Function (event, extra)
 * addCurrent: Function
 * saveCurrent: Function
 * deleteCurrent: Function
 * action: String
 * currentAPI: String
 */
export default class OverlayContainer extends React.Component {
	constructor(props) {
		super(props);
		this.wrapperRef = React.createRef();
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount = () => {
		document.addEventListener("mousedown", this.handleClickOutside);
	};

	componentWillUnmount = () => {
		document.removeEventListener("mousedown", this.handleClickOutside);
	};

	handleClickOutside = (event) => {
		if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
			this.props.changeOverlay({});
		}
	};

	// Switch for Overlay actions
	overlays = () => {
		switch (this.props.action) {
			case "add":
			case "edit":
				return (
					<OverlayEdit
						data={this.props.selectedData}
						saveURL={this.props.saveURL}
						changeOverlay={this.props.changeOverlay}
						overlayArray={this.props.overlayArray}
						addCurrent={this.props.addCurrent}
						saveCurrent={this.props.saveCurrent}
						onChange={this.props.onChange}
						overlayAction={this.props.action}
						currentAPI={this.props.currentAPI}
					/>
				);
			case "delete":
				return (
					<OverlayDelete
						name={
							this.props.selectedData.username
								? this.props.selectedData.username
								: this.props.selectedData.name
						}
						changeOverlay={this.props.changeOverlay}
						actionCurrent={this.props.deleteCurrent}
					/>
				);

			default:
				break;
		}
	};

	render() {
		return (
			<div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-screen bg-gray-400 bg-opacity-70">
				<div
					ref={this.wrapperRef}
					className="bg-white dark:bg-dark-400 rounded-lg shadow px-6 lg:px-8 max-w-md m-auto my-6 sm:mt-24 sm:mb-12 py-4 sm:py-6 xl:py-8"
				>
					{this.overlays()}
				</div>
			</div>
		);
	}
}
