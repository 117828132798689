import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import MainLogo from "../shared/components/Logos/MainLogo";
import Section from "./Section";

export default class Landingpage extends Component {
	state = { opacity: 1 };
	componentDidMount = () => {
		window.addEventListener("scroll", () =>
			this.setState({ opacity: 1 - window.scrollY / window.innerHeight })
		);
	};
	componentWillUnmount = () => {
		window.removeEventListener("scroll", () =>
			this.setState({ opacity: 1 - window.scrollY / window.innerHeight })
		);
	};
	content = [
		{
			text: "Mache deinen Stream zu einem künstlerischen Meisterwerk.",
			image: require("../shared/images/Soon.png"),
			alt: "Soon",
		},
		{
			text: "Mapveto, Facecams, Pausenfüller, Turnierbaum, Schedule integriert mit der API von FACEIT.",
			image: require("../shared/images/Bracket.png"),
			alt: "Bracket",
		},
		{
			text: "Lege dir deine Spieler und Teams selber an.",
			image: require("../shared/images/dbPlayers.png"),
			alt: "dbPlayers",
		},
	];
	render() {
		return (
			<div className="text-white bg-black w-full">
				<img
					src={require("../shared/images/levelup-location.jpg")}
					className="fixed left-0 top-0 w-full h-full object-cover"
					style={{ opacity: this.state.opacity }}
					alt="TM.Live"
				/>
				<header className="relative w-full h-fit">
					<div className="h-16 items-center px-4 bg-primary flex justify-between fixed w-full">
						<MainLogo />
						<div className="bg-secondary px-4 py-1 rounded-lg text-black m-4">
							<NavLink to="/dashboard">Login</NavLink>
						</div>
					</div>
					<div className="flex flex-col pt-36 w-full space-y-20">
						{this.content.map((content, index) => (
							<Section
								key={index + "-" + content.text}
								alt={content.alt}
								text={content.text}
								image={content.image}
								even={index % 2 === 0}
							/>
						))}
					</div>
				</header>
				<div className="h-96"></div>
				<div className="h-96"></div>
				<div className="h-96"></div>
				<div className="h-96"></div>
			</div>
		);
	}
}
