import React from "react";

import Button from "../../../shared/components/UIElements/Button";

/**
 * ListHeader
 *
 * state: Object
 * changeOverlay: Function (selectedData, action)
 * setQuery: Function (limit, page, sort, filter)
 * defaultLimit: Number
 * limitOptions: Array
 */
export default class ListHeader extends React.Component {
	state = {
		limitOptions: [1, 5, 10, 20, 30],
	};

	// Count / Limit and round up
	math = () => {
		return Math.ceil(
			this.props.state.count /
				(typeof this.props.state.limit === "undefined"
					? this.props.defaultLimit
					: this.props.state.limit)
		);
	};

	// Define Pageswitcher
	pageSwitch = () => {
		const limit = 3;
		let maxButtons, between;
		let array = [];

		if (this.math() >= limit) {
			maxButtons = limit;
			if (this.math() > limit) {
				between = true;
			} else {
				between = false;
			}
		} else {
			maxButtons = this.math();
			between = false;
		}

		for (let i = 0; i < maxButtons; i++) {
			array.push(i + 1);
		}
		return { array, between };
	};

	render() {
		return (
			<div className="flex justify-between items-center flex-col">
				<div className="flex justify-between items-center w-full">
					{this.props.state.data.length === 0 && <div />}
					{this.props.state.data.length > 0 && (
						<div>
							<label htmlFor="limit">Entries per page</label>
						</div>
					)}
					{this.math() !== 1 && this.props.state.data.length > 0 && (
						<p className="mx-1 text-center">
							{this.pageSwitch().between && (
								<>Page: {this.props.state.page}</>
							)}
						</p>
					)}
					<div>All Entries: {this.props.state.count}</div>
				</div>
				<div className="flex justify-between items-center mb-4 w-full flex-wrap">
					{this.props.state.data.length === 0 && <div />}
					{this.props.state.data.length > 0 && (
						<div>
							<select
								className="border rounded-lg px-5 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-blue-500 focus:border-blue-500"
								defaultValue={
									typeof this.props.state.limit === "undefined"
										? this.props.defaultLimit
										: this.props.state.limit
								}
								name="limit"
								onChange={(e) => {
									this.props.setQuery(e.target.value, 1);
								}}
							>
								{this.props.limitOptions.map((option) => (
									<option key={option + "optionLimit"} value={option}>
										{option}
									</option>
								))}
							</select>
						</div>
					)}
					{this.props.state.data.length > 0 && this.math() !== 1 && (
						<div className="flex items-center order-3 sm:order-2 w-full sm:w-auto justify-center mt-3 sm:mt-0">
							{this.pageSwitch().between && (
								<>
									<Button
										key={"pageSwitch-(first)"}
										onClick={() => {
											this.props.setQuery(undefined, 1);
										}}
										disabled={this.props.state.page === 1}
										className="mx-1 border flex justify-center items-center text-black"
									>
										<span>First</span>
									</Button>
									<Button
										key={"pageSwitch-(-1)"}
										onClick={() => {
											this.props.setQuery(
												undefined,
												parseInt(this.props.state.page) - 1
											);
										}}
										disabled={this.props.state.page === 1}
										className="mx-1 border flex justify-center items-center text-black"
									>
										<span>-1</span>
									</Button>
									<Button
										key={"pageSwitch-(+1)"}
										onClick={() => {
											this.props.setQuery(
												undefined,
												parseInt(this.props.state.page) + 1
											);
										}}
										disabled={this.props.state.page === this.math()}
										className="mx-1 border flex justify-center items-center text-black"
									>
										<span>+1</span>
									</Button>
									<Button
										key={"pageSwitch-(last)"}
										onClick={() => {
											this.props.setQuery(undefined, this.math());
										}}
										disabled={this.props.state.page === this.math()}
										className="mx-1 border flex justify-center items-center text-black"
									>
										<span>Last</span>
									</Button>
								</>
							)}
							{!this.pageSwitch().between &&
								this.pageSwitch().array.map((value) => (
									<Button
										key={"pageSwitch-" + value}
										onClick={() => {
											this.props.setQuery(undefined, value);
										}}
										disabled={this.props.state.page === value}
										className={
											"mx-1 border flex justify-center items-center text-black"
										}
									>
										<span>{value}</span>
									</Button>
								))}
						</div>
					)}
					<Button
						onClick={() => this.props.changeOverlay({}, "add")}
						green
						className="order-2 sm:order-3"
					>
						Add User
					</Button>
				</div>
			</div>
		);
	}
}
