import { createContext } from "react";

export const ThemeContext = createContext({
	auth: Boolean,
	changeDarkMode: () => { },
	checkError: () => { },
	currentUser: Object,
	darkMode: Boolean,
	findLevel: () => { },
	server: String,
	stages: Array,
	token: String,
});