import React, { Component } from "react";

export default class FormLabel extends Component {
	/**
	 * FormLabel
	 * 
	 * dark: Boolean
	 * name: String
	 * label: String
	 */
	render() {
		return (
			<>
				<label
					className={(this.props.dark ? "text-gray-50" : "text-gray-900 dark:text-gray-50") + " block mb-2 text-sm font-medium first-letter:uppercase"}
					htmlFor={this.props.name}
				>
					{this.props.label ? this.props.label : this.props.name}
				</label>
			</>
		);
	}
}
