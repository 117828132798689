import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faTrophy } from "@fortawesome/free-solid-svg-icons";
import {
	faTwitter,
	faSteam,
	faDiscord,
	faTwitch,
} from "@fortawesome/free-brands-svg-icons";
import FormLabel from "./FormLabel";
import Uploader from "../../FileUploader/Uploader";

/**
 * FormInput
 *
 * className: String
 * name: String
 * type: String
 * dark: Boolean
 * label: String
 * placeholder: String
 * onChange: Function (event, extra)
 * data: Object
 * username: String
 * addon: Object
 * required: Boolean
 * disabled: Boolean
 * saveURL: Function (url)
 */
export default class FormInput extends React.Component {
	icons = (name) => {
		switch (name) {
			case "twitter":
				return faTwitter;
			case "steam":
				return faSteam;
			case "discord":
				return faDiscord;
			case "website":
				return faHouse;
			case "twitch":
				return faTwitch;
			case "tournaments":
				return faTrophy;
			default:
				return <></>;
		}
	};
	render() {
		return (
			<div className={this.props.className}>
				{!this.props.addon && (
					<FormLabel
						name={this.props.name}
						dark={this.props.dark}
						label={
							this.props.label
								? this.props.label
								: this.props.placeholder
						}
					/>
				)}

				<div className="flex">
					{this.props.addon && (
						<span className="inline-flex items-center w-10 justify-center text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
							<FontAwesomeIcon
								icon={this.icons(
									this.props.addon === "tournaments"
										? "tournaments"
										: this.props.name
								)}
							/>
						</span>
					)}
					<input
						disabled={this.props.disabled}
						type={this.props.type}
						name={this.props.name}
						className={
							(this.props.addon
								? "rounded-none rounded-r-lg flex-1 min-w-0"
								: "rounded-lg w-full") +
							(this.props.dark
								? " lg:bg-gray-700 lg:border-gray-700 bg-gray-50 border-gray-300 text-gray-900 lg:text-gray-50"
								: " bg-gray-50 border-gray-300 text-gray-900") +
							" border text-sm focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:!border-gray-700 dark:!bg-gray-700 " +
							(this.props.disabled
								? "cursor-not-allowed dark:!text-gray-400"
								: "dark:!text-gray-50")
						}
						placeholder={this.props.placeholder}
						value={
							this.props.addon
								? this.props.username
									? this.props.username
									: ""
								: this.props.data[this.props.name]
						}
						onChange={
							this.props.addon
								? (e) => this.props.onChange(e, this.props.addon)
								: (e) => this.props.onChange(e)
						}
						required={this.props.required}
					/>
					{this.props.saveURL && (
						<Uploader folder={this.props.currentAPI} saveURL={this.props.saveURL} />
					)}
				</div>
			</div>
		);
	}
}
