import React, { Component } from "react";
import Sponsor from "../../../shared/components/Views/Sponsor";

export default class Winpage extends Component {
	getCurrentTeam = () => {
		return this.props.currentMatch.teams["faction" + this.props.team];
	};

	render() {
		return (
			<div className="relative">
				<div className="absolute top-32 w-full justify-center flex flex-col items-center">
					<div className="text-9xl">WINNER</div>
					{this.props.currentMatch !== "" && (
						<>
							<img
								src={this.getCurrentTeam().avatar}
								alt="Winner"
								className="rounded-xl drop-shadow-3xl mt-20 w-72"
							/>

							<div className="text-5xl mt-20 italic">
								{this.getCurrentTeam().name}
							</div>
						</>
					)}
				</div>
				<Sponsor />
			</div>
		);
	}
}
