import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

/**
 * SearchInput
 *
 * open: Function (value)
 * value: String
 * current: Boolean
 * setQuery: Function (limit, page, sort, filter)
 * allFilters: Array
 */
export default class SearchInput extends React.Component {
	state = {
		filter: "",
	};

	componentDidMount = () => {
		if (typeof this.props.allFilters !== "undefined") {
			this.props.allFilters.forEach((element) => {
				if (element.key === this.props.value) {
					this.setState({ filter: element.value });
				}
			});
		}
	};

	render() {
		return (
			<div className="sm:-ml-6 mr-2 mt-0.5">
				<button onClick={() => this.props.open(this.props.value)}>
					<FontAwesomeIcon icon={faMagnifyingGlass} />
				</button>
				<div
					className={
						(this.props.current ? "" : "hidden") +
						" absolute w-48 h-10 mt-2 -ml-3"
					}
				>
					<input
						type="text"
						name="filter"
						className="absolute h-full bg-gray-50 z-0 border shadow-lg border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pl-8 "
						placeholder="Filter"
						value={this.state.filter}
						onChange={(e) => this.setState({ filter: e.target.value })}
					/>
					<button
						onClick={() =>
							this.props.setQuery(undefined, undefined, undefined, {
								key: this.props.value,
								value: this.state.filter,
							})
						}
						className="absolute z-10 ml-3 h-full"
					>
						<FontAwesomeIcon
							className="text-red-700"
							icon={faMagnifyingGlass}
						/>
					</button>
				</div>
			</div>
		);
	}
}
