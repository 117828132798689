import React, { Component } from "react";
import Button from "../../../shared/components/UIElements/Button";

/**
 * SceneOnOff
 *
 * onClick: Function
 * view: String
 * on: Boolean
 * disabled: Boolean
 */
export default class SceneOnOff extends Component {
	render() {
		return (
			<Button
				green={this.props.on}
				red={!this.props.on}
				onClick={() => this.props.onClick(this.props.view)}
				className="first-letter:uppercase"
				disabled={this.props.disabled}
			>
				{this.props.view}
			</Button>
		);
	}
}
