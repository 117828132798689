import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeContext } from "./shared/context/theme-context.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";

import Loading from "./shared/components/UIElements/Loading";
import LoginWrapper from "./Login/LoginWrapper";
import Logout from "./Login/Logout";
import Dashboard from "./Dashboard/Dashboard";
import Wrapper from "./Views/Wrapper";
import Landingpage from "./Landingpage/Landingpage";
import Uploader from "./shared/components/FileUploader/Uploader";
// import axios from "axios";

export default class App extends React.Component {
	state = {
		auth: false,
		// server: "http://192.168.178.49:3001",
		server: "https://api.tmlive.live",
		token: null,
		darkMode: true,
		loading: true,
		currentUser: undefined,
	};

	// login response
	login = (ok, token) => {
		this.setState({ auth: ok });
		if (ok) {
			this.setState({ token: token });
			localStorage.setItem("token", token);
			this.getCurrentUser(token);
		}
	};

	// logout
	logout = () => {
		localStorage.removeItem("token");
		this.setState({ token: null });
		setTimeout(() => {
			this.setState({ auth: false });
		}, 5000);
	};

	// Check if Error an trigger ToastContainer
	checkError = (data, changeOverlay) => {
		if (data.ok) {
			if (changeOverlay) {
				changeOverlay({});
			}
			return toast.success(data.message);
		} else {
			return toast.error(data.message);
		}
	};

	// Change Dark Mode
	changeDarkMode = () => {
		localStorage.setItem("darkMode", !this.state.darkMode);
		this.setState({ darkMode: !this.state.darkMode });
	};

	// Get Current User
	getCurrentUser = (token) => {
		var decodedJWT = jwt_decode(token);
		fetch(`${this.state.server}/user/manager/${decodedJWT.userId}`, {
			method: "GET",
			headers: { Authorization: "Bearer " + token },
		})
			.then((response) => response.json())
			.then((resp) => {
				this.setState({
					currentUser: resp.data,
				});
			})
			.catch((err) => {
				this.checkError({ message: err.message });
			});
	};

	setStateAfterMount = (data) => {
		this.setState({
			darkMode: data.darkMode,
			token: data.token,
			auth: data.auth,
			loading: data.loading,
		});
	};

	componentDidMount = () => {
		let dataState = {};
		let darkMode = localStorage.getItem("darkMode");
		darkMode = darkMode === "true";
		dataState.darkMode = darkMode;

		const token = localStorage.getItem("token");
		if (token) {
			dataState.token = token;
		} else {
			dataState.auth = false;
			dataState.loading = false;
			this.setStateAfterMount(dataState);
			return;
		}

		fetch(`${this.state.server}/token`, {
			method: "GET",
			headers: {
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.ok) {
					dataState.auth = true;
					dataState.loading = false;
					this.getCurrentUser(token);
				} else {
					dataState.auth = false;
					dataState.loading = false;
				}
				this.setStateAfterMount(dataState);
			})
			.catch(() => {
				this.checkError({ message: "There is an error with the server!" });
			});
	};

	// Context Stages
	stages = [
		{ level: 1, stage: "systemadmin", name: "System Admins" },
		{ level: 2, stage: "useradmin", name: "User Admins" },
		{ level: 3, stage: "mod", name: "Mods" },
		{ level: 4, stage: "tmadmin", name: "Tournament Admins" },
		{ level: 5, stage: "streamer", name: "Streamers" },
		{ level: 6, stage: "participent", name: "Participents" },
		{ level: 7, stage: "standard", name: "Users" },
	];

	findLevel = (user, stageValue) => {
		const find = this.stages.find((stage) => stage.stage === user);
		if (stageValue === "level") {
			return find.level;
		} else if (stageValue === "name") {
			return find.name;
		}
	};

	render() {
		return (
			<ThemeContext.Provider
				value={{
					token: this.state.token,
					auth: this.state.auth,
					server: this.state.server,
					stages: this.stages,
					findLevel: this.findLevel,
					darkMode: this.state.darkMode,
					checkError: this.checkError,
					changeDarkMode: this.changeDarkMode,
					currentUser: this.state.currentUser,
				}}
			>
				<div
					className={
						"font-sans " + (this.state.darkMode ? "dark" : "light")
					}
				>
					<BrowserRouter>
						<Routes>
							<Route path="/" exact element={<Landingpage />} />
							<Route path="/uploader" exact element={<Uploader />} />
							<Route
								path="/view/:stream/main"
								element={<Wrapper view="main" />}
							/>
							<Route
								path="/view/:stream/starting"
								element={<Wrapper view="starting" />}
							/>
							<Route
								path="/view/:stream/win/:team"
								element={<Wrapper view="win" />}
							/>
							<Route
								path="/login"
								element={
									<Loading center loading={this.state.loading}>
										{!this.state.loading &&
											(this.state.auth ? (
												<Navigate to="/dashboard" replace />
											) : (
												<LoginWrapper login={this.login} />
											))}
									</Loading>
								}
							/>
							<Route
								path="/dashboard/*"
								element={
									<Loading center loading={this.state.loading}>
										{!this.state.loading &&
											(this.state.auth ? (
												<Dashboard
													changeDarkMode={this.changeDarkMode}
												/>
											) : (
												<Navigate to="/login" replace />
											))}
									</Loading>
								}
							/>
							<Route
								path="/logout"
								element={<Logout logout={this.logout} />}
							/>
							<Route
								path="/forgot/:id/:token"
								element={<LoginWrapper resetPW login={this.login} />}
							/>
							;
							<Route path="*" element={<Navigate to="/" replace />} />
						</Routes>
					</BrowserRouter>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss={false}
					draggable
					pauseOnHover={false}
					theme={this.state.darkMode ? "dark" : "light"}
				/>
			</ThemeContext.Provider>
		);
	}
}
