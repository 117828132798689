import React from "react";
import { NavLink } from "react-router-dom";

import { ThemeContext } from "../shared/context/theme-context";

/**
 * Button Dashboard
 *
 * Link: String
 * Name: String
 * Icon: FontAwesomeIcon
 * reloadRoute: Function
 */
export default class ButtonDashboard extends React.Component {
	static contextType = ThemeContext;
	render() {
		return (
			<NavLink
				onClick={this.props.reloadRoute.bind(this)}
				to={`./${this.props.link}`}
				className={(navData) =>
					"w-full relative px-4 py-3 flex items-center space-x-4 group border border-white dark:border-dark-400 rounded-xl dark:hover:border-white hover:border-dark-400 " +
					(navData.isActive
						? "bg-gradient-to-r from-sky-600 to-cyan-400"
						: "")
				}
			>
				<div className="w-5 flex justify-center">{this.props.icon}</div>
				<span className="-mr-1 font-medium">{this.props.name}</span>
			</NavLink>
		);
	}
}
