import React from "react";

/**
 * Button
 *
 * formSubmit: Boolean
 * onClick: function
 * disabled: Boolean
 * className: String
 * floatRight: Boolean
 * bg: Color
 * bgHover: Color
 * green: Boolean
 * red: Boolean
 * secondary: Boolean
 * width: String
 * smallPadding: Boolean
 * children: <>
 */
export default class Button extends React.Component {
	render() {
		return (
			<button
				onClick={
					this.props.formSubmit
						? undefined
						: (e) => {
								e.preventDefault();
								this.props.onClick(e);
						  }
				}
				disabled={this.props.disabled}
				className={
					"text-white focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center dark:border-primary " +
					(this.props.floatRight ? " float-right" : "") +
					(this.props.bg ? " " + this.props.bg : " bg-secondary") +
					(this.props.bgHover
						? " " + this.props.bgHover
						: this.props.disabled
						? ""
						: " hover:bg-yellow-500") +
					(this.props.green
						? ` !bg-lime-600 dark:!bg-lime-600 ${
								this.props.disabled
									? ""
									: "hover:!bg-lime-700 dark:hover:!bg-lime-700"
						  }`
						: "") +
					(this.props.red
						? ` !bg-red-600 dark:!bg-red-600 ${
								this.props.disabled
									? ""
									: "hover:!bg-red-700 dark:hover:!bg-red-700"
						  }`
						: "") +
					(this.props.secondary
						? ` !bg-secondary ${
								this.props.disabled ? "" : "hover:!bg-yellow-500"
						  }`
						: "") +
					(this.props.width ? " " + this.props.width : "") +
					(this.props.className ? " " + this.props.className : "") +
					(this.props.disabled ? " bg-gray-300 dark:opacity-60" : "") +
					(this.props.smallPadding ? " px-2 py-1" : " px-5 py-2.5") +
					(this.props.disabled ? " cursor-not-allowed" : "")
				}
				type={this.props.formSubmit ? "submit" : ""}
			>
				{this.props.children}
			</button>
		);
	}
}
