import React, { Component } from "react";

/**
 * Header
 * 
 * headline: String
 * subline: String
 */
export default class Header extends Component {
	render() {
		return (
			<>
				{this.props.headline && (
					<h1 className="text-center text-3xl font-bold">
						{this.props.headline}
					</h1>
				)}
				{this.props.subline && (
					<h2 className="text-center text-xl font-bold">
						{this.props.subline}
					</h2>
				)}
			</>
		);
	}
}
