import React, { Component } from "react";
import { ThemeContext } from "../shared/context/theme-context.js";
import socketIOClient from "socket.io-client";
import Bracket from "./pages/Bracket/Bracket";
import Starting from "./pages/Starting/Starting.jsx";
import Facecam from "./pages/Facecam/Facecam.jsx";
import Schedule from "./pages/Schedule/Schedule.jsx";
import Mapveto from "./pages/Toggles/Mapveto/Mapveto.jsx";

import "@animxyz/core";
import { XyzTransitionGroup } from "@animxyz/react";
import NextMatch from "./pages/Toggles/NextMatch.jsx";
import Winpage from "./pages/Winpage/Winpage.jsx";
import Sponsor from "../shared/components/Views/Sponsor.jsx";
import { ReactComponent as TournamentLogo } from "../shared/images/LogoRoadTo.svg";

/**
 * Main
 *
 * params: Object
 * view: String
 */
export default class Main extends Component {
	static contextType = ThemeContext;

	state = {
		currentMatch: "",
		matches: [],
		participants: [],
		stream: "",
		tournamentID: "",
		veto: [],
		view: "",
		time: "",
		tournamentInfos: {},
		visual: [],
		test: false,
		schedulePage: 1,
		scheduleRound: 1,
		bracket: [],
	};

	streamParams = this.props.params.stream;
	socket = {};
	componentDidMount = () => {
		this.socket = socketIOClient(this.context.server);

		this.socket.emit("changeStream", { new: this.streamParams }, (stream) => {
			this.setState(stream);
		});
		this.socket.on("changeMatch", (match) => {
			this.setState({ veto: [], currentMatch: match });
		});
		this.socket.on("changeMapveto", (veto) => {
			this.setState({ veto: veto });
		});
		this.socket.on("changeView", (view) => {
			this.setState({ view: view });
		});
		this.socket.on("changeTime", (time) => {
			this.setState({ time: time });
		});
		this.socket.on("changeTournament", (stream) => {
			this.setState(stream);
		});
		this.socket.on("changeVisual", (visual) => {
			this.setState({ visual: visual });
		});
		this.socket.on("changeParticipants", (participants) => {
			this.setState({ participants: participants });
		});
		this.socket.on("changeSchedule", (schedule) => {
			this.setState({
				schedulePage: schedule.schedulePage,
				scheduleRound: schedule.scheduleRound,
			});
		});
		this.socket.on("changeBracket", (bracket) => {
			this.setState({ bracket: bracket });
		});
	};
	componentWillUnmount = () => {
		this.socket.off("changeStream");
		this.socket.off("changeMatch");
		this.socket.off("changeMapveto");
		this.socket.off("changeView");
		this.socket.off("changeTime");
		this.socket.off("changeTournament");
		this.socket.off("changeVisual");
		this.socket.off("changeParticipants");
		this.socket.off("changeSchedule");
		this.socket.off("changeBracket");
	};

	view = () => {
		if (this.props.view === "main") {
			return this.state.view;
		} else {
			return this.props.view;
		}
	};

	onlyUnique = (value, index, self) => {
		return self.indexOf(value) === index;
	};

	changeToggle = (visual) => {
		if (!this.socket.emit) {
			return;
		}
		let oldVisual = this.state.visual;
		let newVisual = [];
		newVisual = oldVisual.filter((item) => !visual.hide.includes(item));
		newVisual = [...visual.show, ...newVisual].filter(this.onlyUnique);

		this.socket.emit("changeVisual", this.state.stream, newVisual);

		this.setState({ visual: newVisual });
	};

	componentDidUpdate = (oldProps, oldState) => {
		if (oldState.view !== this.state.view) {
			const toggles = {
				bracket: {
					show: ["tournamentLogo"],
					hide: ["sponsor", "mapveto", "nextMatch"],
				},
				facecams: {
					show: ["tournamentLogo", "sponsor"],
					hide: ["mapveto"],
				},
				starting: {
					show: ["sponsor"],
					hide: ["tournamentLogo", "mapveto"],
				},
			};
			this.changeToggle(toggles[this.state.view]);
		}
	};

	render() {
		return (
			<>
				{this.state.organizerID !== "offline" && (
					<div className="absolute">
						<video width="1920" height="1080" muted autoPlay loop>
							<source
								src={require("../shared/videos/background.mp4")}
								type="video/mp4"
							/>
						</video>
					</div>
				)}
				<div className="w-full h-full tracking-wider font-black overflow-hidden relative">
					<XyzTransitionGroup
						appear
						xyz="fade in-left-100% in-delay-3 out-right-100%"
					>
						{this.view() === "bracket" && (
							<div className="xyz-absolute w-full h-full">
								<Bracket
									changeToggle={this.changeToggle}
									matches={this.state.matches}
									tournamentInfos={this.state.tournamentInfos}
									bracket={this.state.bracket}
								/>
							</div>
						)}
						{this.view() === "starting" && (
							<div className="xyz-absolute w-full h-full">
								<Starting
									time={this.state.time}
									changeToggle={this.changeToggle}
								/>
							</div>
						)}
						{this.view() === "schedule" && (
							<div className="xyz-absolute w-full h-full">
								<Schedule
									matches={this.state.matches}
									scheduleRound={this.state.scheduleRound}
									schedulePage={this.state.schedulePage}
									tournamentInfos={this.state.tournamentInfos}
								/>
							</div>
						)}
						{this.view() === "win" && (
							<div className="xyz-absolute w-full h-full">
								<Winpage
									matches={this.state.matches}
									currentMatch={this.state.currentMatch}
									team={this.props.params.team}
								/>
							</div>
						)}
					</XyzTransitionGroup>
					<div
						className={
							this.view() === "facecams"
								? "inFacecam opacity-0"
								: "outFacecam"
						}
					>
						<Facecam
							changeToggle={this.changeToggle}
							participants={this.state.participants}
							currentView={this.state.view}
						/>
					</div>

					{this.props.view === "main" && (
						<div className="w-full h-full">
							<XyzTransitionGroup appear xyz="fade down-4">
								{this.state.visual.includes("mapveto") && (
									<div className="absolute w-[1720px] h-60 right-[100px] bottom-[50px] flex justify-between">
										<Mapveto
											veto={this.state.veto}
											currentMatch={this.state.currentMatch}
										/>
									</div>
								)}
								{this.state.visual.includes("sponsor") && (
									<div>
										<Sponsor />
									</div>
								)}
							</XyzTransitionGroup>
							<XyzTransitionGroup appear xyz="fade left-4">
								{this.state.visual.includes("nextMatch") && (
									<div className="absolute top-10 left-10 w-80 h-40">
										<NextMatch
											time={this.state.time}
											currentMatch={this.state.currentMatch}
										/>
									</div>
								)}
							</XyzTransitionGroup>
							<XyzTransitionGroup appear xyz="fade right-4">
								{this.state.visual.includes("tournamentLogo") && (
									<div className="absolute top-5 right-20">
										<TournamentLogo className="w-96" />
									</div>
								)}
							</XyzTransitionGroup>
						</div>
					)}
				</div>
			</>
		);
	}
}
