import React, { Component } from "react";

/**
 * Headline
 *
 * className: String
 * title: String
 */
export default class Headline extends Component {
	render() {
		return <h1 className={this.props.className}>{this.props.title}</h1>;
	}
}
