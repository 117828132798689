import React from "react";
import { ThemeContext } from "../shared/context/theme-context";

import MainLogo from "../shared/components/Logos/MainLogo";
import { toast } from "react-toastify";
import FormInput from "../shared/components/UIElements/Forms/FormInput";
import Button from "../shared/components/UIElements/Button";

/**
 * Login
 *
 * login: Function
 * resetPW: Boolean
 * params: Object
 */
export default class Login extends React.Component {
	static contextType = ThemeContext;

	state = {
		username: "",
		email: "",
		password: "",
		checkedTerms: false,
		login: true,
	};

	constructor() {
		super();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.changeLogin = this.changeLogin.bind(this);
	}

	keyDown = (e) => {
		if (e.key === "Enter") {
			this.handleSubmit(e);
		}
	};

	componentDidMount = () => {
		window.addEventListener("keydown", this.keyDown);
		if (this.props.resetPW) {
			fetch(
				`${this.context.server}/user/checkReset/${this.props.params.id}/${this.props.params.token}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
				.then((res) => res.json())
				.then((res) => {
					if (res.ok) {
						this.setState({ username: res.username });
					} else {
						window.location = "/login";
					}
				})
				.catch((err) => {
					window.location = "/login";
				});
		}
	};
	componentWillUnmount = () => {
		window.removeEventListener("keydown", this.keyDown);
	};

	disabledSubmit = () => {
		if (this.props.resetPW) {
			if (this.state.password !== "") {
				return false;
			} else return true;
		}
		if (this.state.username !== "" && this.state.password !== "") {
			if (
				!this.state.login &&
				this.state.email !== "" &&
				this.state.checkedTerms
			) {
				return false;
			} else if (this.state.login) {
				return false;
			} else return true;
		} else return true;
	};

	// handle Input
	handleInputChange = (e) => {
		e.preventDefault();
		const name = e.target.name;
		let value = e.target.value;
		if (name === "checkedTerms") {
			this.setState({ checkedTerms: !this.state.checkedTerms });
		} else {
			this.setState({
				[name]: value,
			});
		}
	};

	// Submit with Serverfetch
	handleSubmit = (e) => {
		e.preventDefault();
		if (this.props.resetPW) {
			fetch(
				`${this.context.server}/user/reset/${this.props.params.id}/${this.props.params.token}`,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						username: this.state.username,
						password: this.state.password,
						token: this.props.params.token,
						id: this.props.params.id,
					}),
				}
			)
				.then((resp) => resp.json())
				.then((data) => {
					if (data.ok) {
						toast.success(data.message);
						this.props.login(true, data.token);
						window.location = "/dashboard";
					} else {
						toast.error(data.message);
						this.setState({ password: "" });
					}
				});
			return;
		}
		if (this.state.login) {
			fetch(`${this.context.server}/user/login`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					username: this.state.username,
					password: this.state.password,
				}),
			})
				.then((resp) => resp.json())
				.then((data) => {
					if (data.ok) {
						toast.success(data.message);
						this.props.login(true, data.token);
					} else {
						toast.error(data.message);
						this.setState({ password: "" });
					}
				});
		} else {
			fetch(`${this.context.server}/user/register`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(this.state),
			})
				.then((resp) => resp.json())
				.then((data) => {
					if (data.ok) {
						toast.success(data.message);
						this.props.login(true, data.token);
					} else {
						toast.error(data.message);
						this.setState({ password: "" });
					}
				});
		}
	};

	// Change Login - SignUp
	changeLogin = (e) => {
		if (e) {
			e.preventDefault();
		}
		this.setState({
			login: !this.state.login,
			email: "",
			password: "",
		});
	};

	forgotPassword = (e) => {
		e.preventDefault();
		fetch(`${this.context.server}/user/forgot`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${this.context.token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ username: this.state.username }),
		})
			.then((resp) => resp.json())
			.then((data) => {
				if (data.ok) {
					toast.success(data.message);
				} else {
					toast.error(data.message);
				}
			});
	};

	// TODO: Slideshow Bilder

	render() {
		return (
			<div className="grid grid-cols-12">
				<div className="col-span-12 lg:col-span-4 text-black lg:text-white font-sans font-bold w-full min-h-screen lg:relative z-20 flex lg:block items-center justify-center flex-col">
					<MainLogo className="bg-white rounded-xl px-4 py-2 mb-6 lg:absolute lg:top-6 lg:left-6" />

					<div className="flex flex-col items-center justify-center w-72 sm:w-96 lg:w-auto lg:h-full bg-white lg:bg-black rounded-xl lg:rounded-none">
						{!this.props.resetPW && (
							<div className="lg:hidden flex w-full font-normal h-10 items-center">
								<button
									onClick={this.changeLogin}
									className={
										(this.state.login
											? "border-b-lime-400"
											: "border-b-gray-400") +
										" w-1/2 border-r-2 h-full items-center flex justify-center border-b-4 border-r-lime-400"
									}
								>
									Login
								</button>
								<button
									onClick={this.changeLogin}
									className={
										(!this.state.login
											? "border-b-lime-400"
											: "border-b-gray-400") +
										" w-1/2 border-l-2 h-full items-center flex justify-center border-b-4 border-l-lime-400"
									}
								>
									Sign up
								</button>
							</div>
						)}
						<form className="lg:px-8 px-5 mt-4 mb-4 xl:scale-140 2xl:scale-160 3xl:scale-180">
							<div className="hidden lg:block xl:text-2xl text-center">
								{this.props.resetPW
									? "Reset Password"
									: this.state.login
									? "Login"
									: "Signup"}
							</div>
							<div className="flex flex-wrap lg:flex-col w-full">
								{!this.state.login && !this.props.resetPW && (
									<FormInput
										className="w-full lg:w-72 text-black lg:text-white lg:mx-auto lg:mb-5"
										name="email"
										type="email"
										label="E-mail"
										dark
										placeholder="Enter your email..."
										onChange={this.handleInputChange}
										data={this.state}
										required
									/>
								)}
								<FormInput
									className="w-full lg:w-72 lg:mx-auto lg:mb-5"
									name="username"
									type="text"
									label="Username"
									dark
									placeholder="Enter your username..."
									onChange={this.handleInputChange}
									data={this.state}
									required
									disabled={this.props.resetPW}
								/>
								<FormInput
									className="w-full lg:w-72 text-black lg:text-white lg:mx-auto lg:mb-5"
									name="password"
									type="password"
									label="Password"
									dark
									placeholder="Enter your password..."
									onChange={this.handleInputChange}
									data={this.state}
									required
								/>
								<div className="flex justify-between lg:w-72 w-full lg:mx-auto">
									{!this.props.resetPW && (
										<div className="flex items-center">
											{this.state.login && (
												<button
													onClick={this.forgotPassword.bind(this)}
													className="text-sm justify-start font-sans font-medium underline"
												>
													Forgot password?
												</button>
											)}
											{!this.state.login && (
												<div className="font-normal text-xs flex justify-center items-center">
													<input
														className="mr-2"
														name="checkedTerms"
														type="checkbox"
														checked={this.state.checkedTerms}
														onChange={this.handleInputChange}
														required
													/>
													<label htmlFor="checkBox">
														I agree to the terms and conditions.
													</label>
												</div>
											)}
										</div>
									)}
									<Button
										className="text-sm font-sans font-medium uppercase"
										green
										onClick={(e) => this.handleSubmit(e)}
										disabled={this.disabledSubmit()}
									>
										{this.props.resetPW
											? "Reset"
											: this.state.login
											? "Login"
											: "Signup"}
									</Button>
								</div>
								{!this.props.resetPW && (
									<button
										onClick={this.changeLogin}
										className="hidden lg:flex font-normal text-xs mt-6 underline lg:mx-auto"
									>
										{this.state.login
											? "Don't have an account? Sign up here!"
											: "Log in here!"}
									</button>
								)}
							</div>
						</form>
					</div>
				</div>

				<div className="col-span-12 lg:col-span-8 text-white font-sans font-bold lg:relative absolute z-10">
					<img
						className="min-h-screen object-cover"
						src={require("../shared/images/levelup-location.jpg")}
						alt="PIC"
					/>
				</div>
			</div>
		);
	}
}
