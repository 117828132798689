import React, { Component } from "react";
import { useParams } from "react-router-dom";
import Main from "./Main";

/**
 * Wrapper
 *
 * view: String
 */
export default class Wrapper extends Component {
	shouldComponentUpdate = () => {
		return false;
	};
	render() {
		const WrapperInside = () => {
			const params = useParams();
			return (
				<div
					style={{
						height: "1080px",
						width: "1920px",
					}}
					className="absolute top-0 right-0 bottom-0 left-0 m-0 p-0 text-white"
				>
					{<Main params={params} view={this.props.view} />}
				</div>
			);
		};
		return <WrapperInside />;
	}
}
