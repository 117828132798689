import React, { Component } from "react";
import TimeToHHMMSS from "../../../shared/components/data/TimeToHHMMSS";

/**
 * Content
 * 
 * time: Number
 */
export default class Content extends Component {
	render() {
		return (
			<>
				<div className="bg-white uppercase text-black h-20 p-1 text-6xl flex justify-center items-center rounded-t-3xl">
					Broadcast continues in
				</div>
				<TimeToHHMMSS
					time={this.props.time}
					className="bg-black h-72 flex items-center justify-center text-secondary text-12xl font-industry rounded-b-3xl tabular-nums"
				/>
			</>
		);
	}
}
