import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

/**
 * AddCard
 *
 * changeOverlay: Function (selectedData, action)
 */
export default class AddCard extends React.Component {
	render() {
		return (
			<button
				style={{ minHeight: "148px" }}
				className="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2 rounded-xl border-2 border-dashed flex justify-center items-center"
				onClick={() => this.props.changeOverlay({}, "add")}
			>
				<FontAwesomeIcon
					icon={faCirclePlus}
					size="3x"
					className="text-gray-400"
				/>
			</button>
		);
	}
}
