import React, { Component } from "react";
import Button from "../../../shared/components/UIElements/Button";
import FormSelect from "../../../shared/components/UIElements/Forms/FormSelect";

import { ThemeContext } from "../../../shared/context/theme-context.js";
import Header from "../Header";

/**
 * Home
 *
 * state: Object
 * handleInputChange: Function
 * tournaments: Array
 * changeQuery: Function
 */
export default class Home extends Component {
	static contextType = ThemeContext;

	state = {
		orgas: [],
		streams: [],
	};

	componentDidMount = () => {
		fetch(`${this.context.server}/content/orgas`, {
			headers: {
				Authorization: "Bearer " + this.context.token,
			},
		})
			.then((resp) => resp.json())
			.then((data) => this.setState({ orgas: data.data }));
		fetch(`${this.context.server}/content/streams`, {
			headers: {
				Authorization: "Bearer " + this.context.token,
			},
		})
			.then((resp) => resp.json())
			.then((data) => this.setState({ streams: data.data }));
	};

	render() {
		return (
			<div>
				<Header headline="Home" />
				<FormSelect
					onChange={this.props.handleInputChange}
					name="currentStream"
					data={this.props.state}
				>
					{this.state.streams.map((stream) => (
						<option key={stream._id} value={stream.name}>
							{stream.name}
						</option>
					))}
				</FormSelect>
				<br />
				<FormSelect
					onChange={this.props.handleInputChange}
					name="currentOrganizer"
					data={this.props.state}
				>
					{this.state.orgas.map((orga) => (
						<option key={orga._id} value={orga.faceitID}>
							{orga.name}
						</option>
					))}
					<option value="offline">Offline</option>
					<option value=""></option>
				</FormSelect>
				<br />
				{this.props.state.currentOrganizer !== "offline" &&
					this.props.state.currentOrganizer !== "" && (
						<>
							<FormSelect
								onChange={this.props.handleInputChange}
								name="currentTournament"
								data={this.props.state}
							>
								{this.props.tournaments.length > 0 &&
									this.props.tournaments.map((tournament) => (
										<option
											key={tournament.id + "-home"}
											value={tournament.id}
										>
											{tournament.name}
										</option>
									))}
								<option value=""></option>
							</FormSelect>
							<div className="mt-4 flex items-center gap-4">
								<Button
									green
									onClick={() => this.props.changeQuery(-1)}
								>
									-100
								</Button>
								<h1>Pages: {this.props.state.loadTournament.offset}</h1>
								<Button
									green
									onClick={() => this.props.changeQuery(+1)}
								>
									+100
								</Button>
							</div>
							<div className="mt-4 flex items-center gap-4">
								<Button green onClick={this.props.reload}>
									RELOAD
								</Button>
							</div>
						</>
					)}
			</div>
		);
	}
}
