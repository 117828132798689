import axios from "axios";
import React, { Component } from "react";
import Button from "../../shared/components/UIElements/Button";
import { ThemeContext } from "../../shared/context/theme-context";

export default class Teams extends Component {
	static contextType = ThemeContext;

	state = {
		allTeams: [],
	};

	startTournament = () => {
		console.log('Test');
	}

	componentDidMount = () => {
		axios.get(`${this.context.server}/db/teams`).then((response) => {
			this.setState({ allTeams: response.data.data });
		});
	};
	render() {
		return (
			<div>
				<div>
					{this.state.allTeams.map((team) => (
						<p key={team._id}>{team.nameLong}</p>
					))}
				</div>
				<Button green onClick={this.startTournament.bind(this)}>
					Start Tournament
				</Button>
			</div>
		);
	}
}
