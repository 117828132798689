import React, { Component } from "react";

/**
 * TimeToHHMMSS
 *
 * time: Number
 * className: String
 */
export default class TimeToHHMMSS extends Component {
	state = {
		time: null,
	};
	toHHMMSS = (secs) => {
		if (secs < 0) {
			return "SOON";
		}
		var sec_num = parseInt(secs, 10);
		var hours = Math.floor(sec_num / 3600);
		var minutes = Math.floor(sec_num / 60) % 60;
		var seconds = sec_num % 60;

		return [hours, minutes, seconds]
			.map((v) => (v < 10 ? "0" + v : v))
			.filter((v, i) => v !== "00" || i > 0)
			.join(":");
	};
	getCountdown = () => {
		const date = new Date();
		const remain = this.props.time - Math.round(date.getTime() / 1000);
		this.setState({ time: remain });
	};

	componentDidMount = () => {
		this.getCountdown();
		const interval = setInterval(() => {
			this.getCountdown();
			if (this.state.time < 0) {
				clearInterval(interval);
			}
		}, 1000);
	};

	componentDidUpdate = (oldProps) => {
		if (oldProps.time !== this.props.time) {
			this.getCountdown();
			const interval = setInterval(() => {
				this.getCountdown();
				if (this.state.time < 0) {
					clearInterval(interval);
				}
			}, 1000);
		}
	};
	render() {
		return (
			<div className={this.props.className}>
				{this.toHHMMSS(this.state.time)}
			</div>
		);
	}
}
