import React, { Component } from "react";
import Button from "../../../shared/components/UIElements/Button";
import Header from "../Header";
import Participant from "./Participant";

/**
 * Participants
 *
 * participants: Array
 * saveData: Function
 */
export default class Participants extends Component {
	state = {
		indexSelected: -1,
	};
	addParticipant = () => {
		let allIndexes = [];
		this.props.participants.forEach((participant) =>
			allIndexes.push(participant.index)
		);
		let indexMax = allIndexes.length === 0 ? 0 : Math.max(...allIndexes) + 1;
		const newParticipant = {
			name: "",
			url: "",
			social: "",
			live: false,
			index: indexMax,
		};
		let array = [...this.props.participants, newParticipant];
		this.props.saveData(array);
	};

	changeIndexSelected = (index) => {
		this.setState({ indexSelected: index });
	};

	saveAllParticipants = (participant) => {
		this.changeIndexSelected(-1);
		const newArr = this.props.participants.map((obj) =>
			obj.index === participant.index ? participant : obj
		);
		this.props.saveData(newArr);
	};

	deleteParticipant = (index) => {
		const array = this.props.participants;
		const newArr = array.filter((object) => object.index !== index);
		this.changeIndexSelected(-1);
		this.props.saveData(newArr);
	};

	render() {
		return (
			<div>
				<Header headline="Participants" />
				<div className="flex gap-4 flex-wrap">
					{this.props.participants.length > 0 &&
						this.props.participants.map((participant, i) => (
							<Participant
								key={i + "-participant"}
								className="w-72"
								participant={participant}
								saveAllParticipants={this.saveAllParticipants}
								deleteParticipant={this.deleteParticipant}
								indexSelected={this.state.indexSelected}
								changeIndexSelected={this.changeIndexSelected}
							/>
						))}
				</div>
				<Button className="mt-4" green onClick={this.addParticipant}>Add new Participant</Button>
			</div>
		);
	}
}
