import React, { Component } from "react";
import TimeToHHMMSS from "../../../shared/components/data/TimeToHHMMSS";

export default class NextMatch extends Component {
	render() {
		return (
			<div className="w-full h-full rounded-xl border-4 border-white flex flex-col">
				<div className="h-8 justify-center flex ">
					<div className="pr-2 h-full flex justify-end items-center">
						Next Match in:
					</div>
					<TimeToHHMMSS
						time={this.props.time}
						className="font-industry pt-0.5 h-full flex justify-start items-center tabular-nums"
					/>
				</div>
				<div className="flex w-full justify-between h-full items-center">
					<div className="w-36 flex flex-col justify-center text-center">
						<div className="flex justify-center items-center">
							<img
								className="w-20 rounded-full mb-2"
								src={this.props.currentMatch.teams.faction1.avatar}
								alt="Logo Team1"
							/>
						</div>
						<div className="text-sm">
							{this.props.currentMatch.teams.faction1.name}
						</div>
					</div>
					<div className="w-8 flex items-center justify-center h-full text-xl">
						vs.
					</div>
					<div className="w-36 flex flex-col justify-center text-center">
						<div className="flex justify-center items-center">
							<img
								className="w-20 rounded-full mb-2"
								src={this.props.currentMatch.teams.faction2.avatar}
								alt="Logo Team2"
							/>
						</div>
						<div className="text-sm">
							{this.props.currentMatch.teams.faction2.name}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
