import React from "react";

/**
 * MainLogo
 *
 * className: String
 */
export default class MainLogo extends React.Component {
	render() {
		return (
			<a href="/" title="TM.Live" className={this.props.className}>
				<img
					src={require("../../images/TMLIVE.png")}
					className="w-32"
					alt="TM.Live"
				/>
			</a>
		);
	}
}
