import React from "react";

/**
 * FormCheckbox
 *
 * className: String
 * name: String
 * label: String
 * onChange: Function (event, extra)
 * data: Object
 * required: Boolean
 */
export default class FormCheckbox extends React.Component {
	render() {
		return (
			<label className="flex">
				<input
					className="w-4 h-4"
					name={this.props.name}
					type="checkbox"
					checked={!this.props.data[this.props.name]}
					onChange={(e) => this.props.onChange(e, "disabled")}
				/>
				<div className="text-sm font-medium text-gray-900 dark:text-gray-300">
					{this.props.label}
				</div>
			</label>
		);
	}
}
