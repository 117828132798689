import React from "react";
import OverlayButtons from "./OverlayButtons";

/**
 * OverlayDelete
 *
 * name: String
 * changeOverlay: Function (currentData)
 * actionCurrent: Function
 */
export default class OverlayDelete extends React.Component {
	render() {
		return (
			<form onSubmit={this.props.actionCurrent}>
				<div className="mb-4 text-center text-lg">
					Do you want to delete "{this.props.name}"?
				</div>
				<OverlayButtons
					closeClick={this.props.changeOverlay}
					formSubmit
					submitName="Delete"
				/>
			</form>
		);
	}
}
