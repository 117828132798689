import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

/**
 * SortButton
 *
 * state: Number
 * value: Number
 * function: Function
 */
export default class SortButton extends React.Component {
	render() {
		return (
			<div className="flex">
				<button
					className="font-bold text-sm"
					onClick={() => {
						if (this.props.state === this.props.value) {
							this.props.setQuery(undefined, 1, "-" + this.props.value);
						} else {
							this.props.setQuery(undefined, 1, this.props.value);
						}
					}}
				>
					{this.props.children}
				</button>

				{this.props.state === this.props.value && (
					<div className="flex items-center ml-2">
						<FontAwesomeIcon icon={faCaretDown} />
					</div>
				)}
				{this.props.state === "-" + this.props.value && (
					<div className="flex items-center ml-2">
						<FontAwesomeIcon icon={faCaretUp} />
					</div>
				)}
			</div>
		);
	}
}
