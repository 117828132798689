import React, { Component } from "react";

import ReactFlagsSelect from "react-flags-select";
import Button from "../Button";
import FormLabel from "./FormLabel";

/**
 * FormCountry
 *
 * className: String
 * name: String
 * country: String
 * onChange: Function
 */
export default class FormCountry extends Component {
	render() {
		return (
			<div className={this.props.className}>
				<FormLabel name={this.props.name} />
				<div name={this.props.name} className="flex w-full">
					<ReactFlagsSelect
						searchable
						className="dark:bg-gray-700 rounded-lg grow"
						selected={this.props.country}
						onSelect={(code) =>
							this.props.onChange(undefined, this.props.name, code)
						}
					/>
					<div className="ml-2">
						<Button
							className="h-full"
							onClick={() =>
								this.props.onChange(undefined, this.props.name, "")
							}
							red
						>
							X
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
