import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";

/**
 * Facecam
 *
 * participants: Array
 * currentView: String
 */
export default class Facecam extends Component {
	state = {
		liveP: [],
	};
	settings = () => {
		switch (this.state.liveP.length) {
			case 1:
				return {
					width: "100%",
					height: "100%",
					top: "0",
					left: "0",
				};
			case 2:
				return [
					{
						top: "244px",
						left: "200px",
						width: "721px",
						height: "407px",
					},
					{
						top: "244px",
						right: "200px",
						width: "721px",
						height: "407px",
					},
				];
			default:
				return [];
		}
	};

	componentDidMount = () => {
		this.setState({ liveP: this.props.participants.filter((p) => p.live) });
	};

	componentDidUpdate = (oldProps) => {
		if (oldProps.participants !== this.props.participants) {
			this.setState({
				liveP: this.props.participants.filter((p) => p.live),
			});
		}
	};

	render() {
		return (
			<div className="relative">
				{this.state.liveP.length > 0 &&
					this.state.liveP.map((p, i) => (
						<div
							key={`Cam-${i}`}
							style={this.settings()[i]}
							className="absolute"
						>
							<iframe
								src={p.url}
								allow="autoplay"
								muted
								frameBorder="0"
								title={`Cam-${i}`}
								className="w-full h-full"
							/>
							<div className="relative h-[100px] bg-white w-full text-black tracking-normal flex items-center flex-col justify-center px-8">
								<div className="flex w-full text-3xl mb-1">
									{p.name}
								</div>
								<div className="flex w-full text-gray-600">
									<FontAwesomeIcon icon={faTwitter} className="mr-2" />
									<div className="flex w-full font-semibold font-industry text-sm -mt-0.5">
										@{p.social}
									</div>
								</div>
							</div>
						</div>
					))}
			</div>
		);
	}
}
