import React from "react";

import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

import { ThemeContext } from "../../context/theme-context.js";

import axios from "axios";


/**
 * Uploader
 * 
 * path: String
 * folder: String
 * saveURL: Function
 * 
 */
export default class Uploader extends React.Component {
	static contextType = ThemeContext;
	path = () => {
		if (this.props.path) {
			return this.props.path;
		} else {
			return "user";
		}
	};

	onFileChange = (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		formData.append("image", file);
		const config = {
			url: `${this.context.server}/upload/${this.props.folder
				.split("/")
				.pop()}`,
			method: "POST",
			data: formData,
			headers: {
				Authorization: "Bearer " + this.context.token,
			},
		};
		axios(config)
			.then((resp) => {
				toast.success(resp.message);
				this.props.saveURL(resp.data.data.url);
			})
			.catch((err) => {
				toast.error(err.message);
			});
	};

	render() {
		return (
			<label className="border rounded-lg p-2 cursor-pointer w-60 ml-2 bg-yellow-500">
				<input
					type="file"
					className="hidden"
					onChange={(e) => this.onFileChange(e)}
				/>
				<FontAwesomeIcon className="mr-2" icon={faPencil} />
				<span>Custom Upload</span>
			</label>
		);
	}
}
