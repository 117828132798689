import React from "react";

import { NavLink } from "react-router-dom";
import { ThemeContext } from "../shared/context/theme-context.js";

import ButtonDashboard from "./ButtonDashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { UserDefault } from "../shared/cloudinary/currentUser";
import { AdvancedImage } from "@cloudinary/react";
import MainLogo from "../shared/components/Logos/MainLogo.jsx";

/**
 * Sidebar
 *
 * pages: Array
 * reloadRoute: Function
 */
export default class Sidebar extends React.Component {
	static contextType = ThemeContext;

	displayName = () => {
		const user = this.context.currentUser;
		const username = user.username;
		const firstName = user.firstName;
		const lastName = user.lastName;
		if (firstName) {
			if (lastName) {
				return `${firstName} ${lastName}`;
			} else {
				return firstName;
			}
		} else if (lastName) {
			return lastName;
		} else {
			return username;
		}
	};

	render() {
		return (
			<>
				<div>
					<div className="dark:bg-gray-50 p-2 w-32 rounded-xl my-4 hidden lg:block">
						<MainLogo />
					</div>
					<div className="h-12 lg:hidden"></div>

					<div className="mt-8 text-center">
						<div className="flex justify-center">
							<AdvancedImage
								className="rounded-full w-20"
								cldImg={UserDefault("default")}
								alt="User PIC"
							/>
						</div>
						<h5 className="mt-4 text-xl font-semibold">
							{this.displayName()}
						</h5>
						<span className="text-gray-400 italic">
							{this.context
								.findLevel(this.context.currentUser.level, "name")
								.substring(
									0,
									this.context.findLevel(
										this.context.currentUser.level,
										"name"
									).length - 1
								)}
						</span>
					</div>

					<ul className="space-y-2 tracking-wide mt-8">
						{this.props.pages.map((btn, i) => (
							<li key={i + "-pages"}>
								{(this.context.findLevel(
									this.context.currentUser.level,
									"level"
								) === 1 ||
									(this.context.findLevel(
										this.context.currentUser.level,
										"level"
									) >= btn.levelMin &&
										this.context.findLevel(
											this.context.currentUser.level,
											"level"
										) <= btn.levelMax)) && (
									<ButtonDashboard
										name={btn.name}
										link={btn.link}
										icon={<FontAwesomeIcon icon={btn.icon} />}
										reloadRoute={this.props.reloadRoute}
									/>
								)}
							</li>
						))}
					</ul>
				</div>

				<div className="px-6 -mx-6 pt-4 flex justify-between items-center border-t group">
					<NavLink
						to="/logout"
						className="px-4 py-3 flex items-center space-x-4 rounded-md dark:group-hover:text-secondary group-hover:text-gray-900"
					>
						<FontAwesomeIcon icon={faRightFromBracket} />
						<span>Logout</span>
					</NavLink>
				</div>
			</>
		);
	}
}
