import React, { Component } from "react";
import Content from "./Content";
import { ReactComponent as TournamentLogo } from "../../../shared/images/LogoRoadTo.svg";

/**
 * Starting
 * 
 * time: Number
 */
export default class Starting extends Component {
	render() {
		return (
			<div>
				<div className="absolute top-[116px] left-[666px]">
					<TournamentLogo />
				</div>
				<div
					className="absolute w-[1200px] top-[420px] left-[360px] rounded-3xl"
					style={{
						"boxShadow":
							"2px 2px 30px rgba(255, 255, 255, 0.4),-2px 2px 30px rgba(255, 255, 255, 0.4), 2px -2px 30px rgba(255, 255, 255, 0.4), -2px -2px 30px rgba(255, 255, 255, 0.4)",
					}}
				>
					<Content time={this.props.time} />
				</div>
			</div>
		);
	}
}
