import React from "react";

import Button from "../UIElements/Button";

/**
 * OverlayButtons
 *
 * MarginTop: String
 * closeClick: Function
 * formSubmit: Boolean
 * submitName: String
 * closeName: String
 */
export default class OverlayButtons extends React.Component {
	render() {
		return (
			<div
				className={
					(this.props.marginTop ? this.props.marginTop : "pt-1 sm:pt-4") +
					" flex"
				}
			>
				<div className="w-1/2 pl-1 order-2">
					<Button
						onClick={this.props.submitClick}
						width="w-4/5"
						green
						floatRight
						formSubmit={this.props.formSubmit}
					>
						{this.props.submitName || "Save"}
					</Button>
				</div>
				<div className="w-1/2 pr-1 order-1">
					<Button onClick={this.props.closeClick} width="w-4/5" red>
						{this.props.closeName || "Close"}
					</Button>
				</div>
			</div>
		);
	}
}
