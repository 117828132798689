import React from "react";

import SortButton from "../../../shared/components/UIElements/SortButton";
import SearchInput from "../../../shared/components/UIElements/SearchInput";
/**
 * ListTableHeader
 *
 * tableSchema: Array
 * setQuery: Function (limit, page, sort, filter)
 * sort: String
 * allFilters: Array
 * exampleSchema: Object
 */
export default class ListTableHeader extends React.Component {
	state = {
		openSearch: "",
	};

	openFilter = (value) => {
		if (this.state.openSearch === value) {
			this.setState({ openSearch: undefined });
		} else {
			this.setState({ openSearch: value });
		}
	};

	mobile = (mobile, head) => {
		if (mobile) {
			return head.charAt(0) + head.charAt(1) + ".";
		}
		return head;
	};

	render() {
		return (
			<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-white">
				<tr className="rounded-t-lg">
					<th className="text-left px-6 py-3 hidden sm:table-cell rounded-tl-lg"></th>
					{this.props.tableSchema(this.props.exampleSchema).map((head, i) => (
						<th
							className={
								"p-1 sm:px-5 sm:py-2 lext-left " +
								(head.xlHide
									? "xl:table-cell hidden "
									: head.lgHide
									? "lg:table-cell hidden "
									: "")
							}
							key={i + '-schemaHeader'}
						>
							<div className="flex">
								<SearchInput
									value={head.sort}
									current={this.state.openSearch === head.sort}
									open={this.openFilter}
									setQuery={this.props.setQuery}
									allFilters={this.props.allFilters}
								/>

								<SortButton
									state={this.props.sort}
									value={head.sort}
									setQuery={this.props.setQuery}
								>
									<span className="sm:hidden">
										{this.mobile(true, head.head)}
									</span>
									<span className="hidden sm:block">
										{this.mobile(false, head.head)}
									</span>
								</SortButton>
							</div>
						</th>
					))}
					<th className="text-center px-6 py-3 rounded-tr-lg">Actions</th>
				</tr>
			</thead>
		);
	}
}
