import React, { Component } from "react";
// import Button from "../../../shared/components/UIElements/Button";
import Header from "../Header";

import SetTime from "./SetTime";
import Views from "./Views";

/**
 * Live
 *
 * state: Object
 * changeTime: Function
 * changeView: Function
 * changeVisual: Function
 * schedulePage: Number
 * scheduleRound: Number
 * changeSchedule: Function
 */
export default class Live extends Component {
	render() {
		return (
			<div className="flex gap-4 flex-col">
			<Header headline="Live" />
				<div className="w-80 ">
					Live in
					<SetTime
						time={this.props.state.time}
						changeTime={this.props.changeTime}
					/>
				</div>
				<Views
					state={this.props.state}
					changeView={this.props.changeView}
					changeVisual={this.props.changeVisual}
				/>
				<br />
				{/* <div className="flex gap-4">
					<div className="flex w-44 flex-col">
						<div className="w-full text-center mb-4">Schedule Round</div>
						<div className="flex">
							<Button
								className="w-1/2 text-black"
								onClick={() =>
									this.props.changeSchedule({
										schedulePage: this.props.state.schedulePage,
										scheduleRound: this.props.state.scheduleRound - 1,
									})
								}
							>
								-1
							</Button>
							<div className="mx-4">
								{this.props.state.scheduleRound}
							</div>
							<Button
								className="w-1/2 text-black"
								onClick={() => {
									this.props.changeSchedule({
										schedulePage: this.props.state.schedulePage,
										scheduleRound: this.props.state.scheduleRound + 1,
									});
								}}
							>
								+1
							</Button>
						</div>
					</div>
					<div className="flex w-44 flex-col">
						<div className="w-full text-center mb-4">Schedule Page</div>
						<div className="flex">
							<Button
								className="w-1/2 text-black"
								onClick={() =>
									this.props.changeSchedule({
										schedulePage: this.props.state.schedulePage - 1,
										scheduleRound: this.props.state.scheduleRound,
									})
								}
							>
								-1
							</Button>
							<div className="mx-4">{this.props.state.schedulePage}</div>
							<Button
								className="w-1/2 text-black"
								onClick={() =>
									this.props.changeSchedule({
										schedulePage: this.props.state.schedulePage + 1,
										scheduleRound: this.props.state.scheduleRound,
									})
								}
							>
								+1
							</Button>
						</div>
					</div>
				</div> */}
			</div>
		);
	}
}
