import React from "react";

import ListHeader from "./List/ListHeader";
import ListTableHeader from "./List/ListTableHeader";
import ListTableBody from "./List/ListTableBody";

/**
 * DashboardList
 *
 * state: Object
 * changeOverlay: Function (selectedData, action)
 * setQuery: Function (limit, page, sort, filter)
 * defaultLimit: Number
 * limitOptions: Array
 * tableSchema: Array
 * exampleSchema: Object
 * deleteFilter: Function
 */
export default class DashboardList extends React.Component {
	render() {
		return (
			<>
				<ListHeader
					state={this.props.state}
					changeOverlay={this.props.changeOverlay}
					setQuery={this.props.setQuery}
					defaultLimit={this.props.defaultLimit}
					limitOptions={this.props.limitOptions}
				/>

				<table className="w-full text-sm text-left">
					<ListTableHeader
						tableSchema={this.props.tableSchema}
						setQuery={this.props.setQuery}
						sort={this.props.state.sort}
						allFilters={this.props.state.filter}
						exampleSchema={this.props.exampleSchema}
					/>

					<tbody>
						{this.props.state.data.map((entry, i) => (
							<ListTableBody
								entry={entry}
								tableSchema={this.props.tableSchema(entry)}
								index={i}
								key={entry._id}
								changeOverlay={this.props.changeOverlay}
							/>
						))}
					</tbody>
				</table>
			</>
		);
	}
}
