import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import {
	faUsers,
	faTowerBroadcast,
	faTrophy,
	faGamepad,
	faSitemap,
	faUsersLine,
	faUser,
	faSun,
	faMoon,
} from "@fortawesome/free-solid-svg-icons";

import { ThemeContext } from "../shared/context/theme-context.js";

import Sidebar from "./Sidebar";

import AllPicText from "./pages/AllPicText";
import AllDataList from "./pages/AllDataList";
import MyStream from "./MyStream/MyStream";
import MyTournament from "./MyTournament/MyTournament";

import OverlayContainer from "../shared/components/Overlay/OverlayContainer";

import "@animxyz/core";
import { XyzTransition } from "@animxyz/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainLogo from "../shared/components/Logos/MainLogo.jsx";

/**
 * Dashboard
 *
 * changeDarkMode: Function
 */
export default class Dashboard extends React.Component {
	static contextType = ThemeContext;

	state = {
		sidebar: false,
		data: [],
		selectedData: {},
		count: Number,
		currentAPI: String,
		overlayOpen: false,
		overlayAction: String,
		overlayArray: Array,
		reload: false,
		limit: 10,
		page: 1,
		sort: undefined,
		filter: undefined,
	};

	// DB-Communication
	// method: "GET", "POST", "PUT", "DELETE"
	// apiURL: current API from page
	// Callback: Function
	dbActions = (method, apiURL, callback) => {
		let params = {};
		if (this.state.limit) {
			params.limit = this.state.limit;
		}
		if (this.state.page) {
			params.page = this.state.page;
		}
		if (this.state.sort) {
			params.sort = this.state.sort;
		}
		if (this.state.filter !== undefined && this.state.filter.length !== 0) {
			for (const thisFilter of this.state.filter) {
				params[thisFilter.key] = thisFilter.value;
			}
		}
		if (params.page === 1) {
			delete params.page;
		}
		const searchParams =
			Object.keys(params).length !== 0
				? "?" + new URLSearchParams(params)
				: "";

		switch (method) {
			case "GET":
				console.log("load from DB", apiURL);
				fetch(`${this.context.server}/${apiURL}${searchParams}`, {
					method: method,
					headers: { Authorization: "Bearer " + this.context.token },
				})
					.then((response) => response.json())
					.then((resp) => {
						this.setState({
							data: resp.data,
							count: resp.count,
							reload: false,
						});
						if (typeof callback !== "undefined") {
							callback();
						}
					})
					.catch((err) => {
						this.context.checkError({ message: err.message });
					});
				break;

			case "POST":
				fetch(`${this.context.server}/${apiURL}`, {
					method: method,
					headers: {
						Authorization: "Bearer " + this.context.token,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(this.state.selectedData),
				})
					.then((response) => response.json())
					.then((data) => {
						this.dbResponse(data);
					})
					.catch(() => {
						this.context.checkError({
							message: "There is an error with the server!",
						});
					});
				break;

			case "PUT":
				fetch(
					`${this.context.server}/${apiURL}/${this.state.selectedData._id}`,
					{
						method: method,
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + this.context.token,
						},
						body: JSON.stringify(this.state.selectedData),
					}
				)
					.then((response) => response.json())
					.then((data) => {
						this.dbResponse(data);
					})
					.catch(() => {
						this.context.checkError({
							message: "There is an error with the server!",
						});
					});
				break;

			case "DELETE":
				fetch(
					`${this.context.server}/${apiURL}/${this.state.selectedData._id}`,
					{
						method: method,
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + this.context.token,
						},
						body: JSON.stringify(this.state.selectedData),
					}
				)
					.then((response) => response.json())
					.then((data) => {
						this.dbResponse(data);
					})
					.catch(() => {
						this.context.checkError({
							message: "There is an error with the server!",
						});
					});
				break;

			default:
				break;
		}
	};

	// Check Error
	dbResponse = (data) => {
		this.context.checkError(data, this.changeOverlay);
		this.setState({ reload: true });
	};

	// Change API
	changeCurrentAPI = (apiURL) => {
		this.setState({ currentAPI: apiURL });
	};

	// Change Current Overlay Array
	changeCurrentOverlayArray = (overlayArray) => {
		this.setState({ overlayArray: overlayArray });
	};

	// Change current data in Overlay
	changeSelectedData = (event, extra, id) => {
		switch (extra) {
			case "disabled":
				this.setState({
					selectedData: {
						...this.state.selectedData,
						disabled: !event.target.checked,
					},
				});
				return;
			case "socials":
			case "tournaments":
				const name = event.target.name;
				let value = event.target.value;
				let object;

				if (extra === "socials") {
					object = { name: name, username: value };
				} else if (extra === "tournaments") {
					object = { name: value };
				}

				if (typeof this.state.selectedData[extra] !== "undefined") {
					let arrayNames = [];
					this.state.selectedData[extra].forEach((el) => {
						arrayNames.push(el.name);
						if (el.name === object.name) {
							const filtered = this.state.selectedData[extra].filter(
								(f) => f.name !== object.name
							);
							filtered.push(object);
							this.setState({
								selectedData: {
									...this.state.selectedData,
									[extra]: filtered,
								},
							});
						}
					});
					if (!arrayNames.includes(object.name)) {
						this.setState({
							selectedData: {
								...this.state.selectedData,
								[extra]: [...this.state.selectedData[extra], object],
							},
						});
					}
				} else {
					this.setState({
						selectedData: {
							...this.state.selectedData,
							[extra]: [object],
						},
					});
				}
				return;
			case "country":
				this.setState({
					selectedData: { ...this.state.selectedData, country: id },
				});
				return;
			default:
				this.setState({
					selectedData: {
						...this.state.selectedData,
						[event.target.name]: event.target.value,
					},
				});
				return;
		}
	};

	// Change Sidebar on pressing Hamburger
	changeSidebar = () => {
		this.setState({
			sidebar: !this.state.sidebar,
		});
	};

	// Open and Close Overlay
	changeOverlay = (selectedData, action) => {
		this.setState({
			overlayOpen: !this.state.overlayOpen,
			selectedData: selectedData,
			overlayAction: action,
		});
	};

	// Set Query
	setQuery = (limit, page, sort, filterQuery) => {
		if (limit !== this.state.limit && typeof limit !== "undefined") {
			this.setState({ limit: parseInt(limit) });
		}
		if (page !== this.state.page && typeof page !== "undefined") {
			this.setState({ page: page });
		}
		if (sort !== this.state.sort && typeof sort !== "undefined") {
			this.setState({ sort: sort });
		}
		if (
			filterQuery !== this.state.filter &&
			typeof filterQuery !== "undefined"
		) {
			this.setState({ page: 1 });
			if (typeof this.state.filter !== "undefined") {
				let filterKeys = [];
				this.state.filter.forEach((el) => {
					filterKeys.push(el.key);
					if (el.key === filterQuery.key) {
						const filtered = this.state.filter.filter(
							(f) => f.key !== filterQuery.key
						);
						filtered.push(filterQuery);
						this.setState({ filter: filtered });
					}
				});
				if (!filterKeys.includes(filterQuery.key)) {
					this.setState({
						filter: [...this.state.filter, filterQuery],
					});
				}
			} else {
				this.setState({ filter: [filterQuery] });
			}
		}
	};

	// delete Filter
	deleteFilter = () => {
		this.setState({ filter: undefined });
	};

	// find User Level in stages
	stages = this.context.stages;
	findLevel = (user, stageValue) => {
		const find = this.stages.find((stage) => stage.stage === user);
		if (stageValue === "level") {
			return find.level;
		} else if (stageValue === "name") {
			return find.name;
		}
	};

	// Check if the user is allowed to see the Route
	enabledRoute = (start, end) => {
		let ok;
		this.context.findLevel(this.context.currentUser.level, "level") === 1 ||
		(this.context.findLevel(this.context.currentUser.level, "level") >=
			start &&
			this.context.findLevel(this.context.currentUser.level, "level") <= end)
			? (ok = true)
			: (ok = false);
		return ok;
	};

	// Reload Route for new Data Fetch
	reloadRoute = () => {
		this.setState({ reload: true });
	};

	buttonsSidebar = [
		{
			levelMin: 1,
			levelMax: 1,
			name: "All Games",
			link: "admin/games",
			icon: faGamepad,
		},
		{
			levelMin: 1,
			levelMax: 3,
			name: "All Users",
			link: "admin/users",
			icon: faUsers,
		},
		{
			levelMin: 1,
			levelMax: 3,
			name: "All Organizers",
			link: "admin/orgas",
			icon: faSitemap,
		},
		{
			levelMin: 1,
			levelMax: 3,
			name: "All Seasons",
			link: "admin/seasons",
			icon: faTrophy,
		},
		{
			levelMin: 1,
			levelMax: 3,
			name: "All Streams",
			link: "admin/streams",
			icon: faTowerBroadcast,
		},
		{
			levelMin: 2,
			levelMax: 5,
			name: "My Tournament",
			link: "tournament",
			icon: faSitemap,
		},
		{
			levelMin: 2,
			levelMax: 5,
			name: "My Stream",
			link: "stream",
			icon: faTowerBroadcast,
		},
		{
			levelMin: 1,
			levelMax: 3,
			name: "DB Teams",
			link: "db/teams",
			icon: faUsersLine,
		},
		{
			levelMin: 1,
			levelMax: 3,
			name: "DB Players",
			link: "db/players",
			icon: faUser,
		},
	];

	render() {
		return (
			<div className="dark:text-white text-gray-600">
				<aside
					className={
						(this.state.sidebar ? "" : "ml-[-100%] ") +
						"fixed z-10 top-0 pb-3 px-6 w-full flex flex-col justify-between h-screen border-r transition duration-300 lg:ml-0 sm:w-1/2 lg:w-1/4 xl:w-1/5 2xl:w-[15%] overflow-auto dark:bg-dark-400 bg-white"
					}
				>
					{this.context.currentUser && (
						<Sidebar
							pages={this.buttonsSidebar}
							reloadRoute={this.reloadRoute}
						/>
					)}
				</aside>

				<div className="ml-auto lg:w-3/4 xl:w-4/5 2xl:w-[85%] h-full min-h-screen bg-white dark:bg-dark-900 ">
					{/* Topbar */}
					{/* ADD: Add Messages and Notifications and "My Profile"-Page */}
					<div className="sticky z-10 top-0 h-16 border-b lg:py-2.5 4xl:container dark:bg-dark-400 bg-white">
						<div className="px-6 flex items-center justify-between space-x-4">
							<h5 hidden className="text-2xl font-medium lg:block">
								Dashboard
							</h5>
							<div className="flex">
								<button
									className="w-12 h-16 -mr-2 border-r lg:hidden"
									onClick={this.changeSidebar}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-6 w-6 my-auto"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M4 6h16M4 12h16M4 18h16"
										/>
									</svg>
								</button>
								<div className="dark:bg-gray-50 p-2 w-24 rounded-xl my-4 block lg:hidden ml-4">
									<MainLogo />
								</div>
							</div>
							<div className="flex space-x-4">
								<button
									aria-label="DarkMode"
									className={
										"w-10 h-10 rounded-xl relative border " +
										(this.context.darkMode
											? "bg-gray-900"
											: "bg-gray-50")
									}
									onClick={this.context.changeDarkMode.bind(this)}
								>
									<div className="relative p-1.5">
										<XyzTransition xyz="small-100% origin-center">
											{!this.context.darkMode && (
												<div className="absolute -top-1 left-2.5">
													<FontAwesomeIcon
														icon={faSun}
														className="text-gray-600"
													/>
												</div>
											)}
										</XyzTransition>
										<XyzTransition xyz="small-100% origin-center">
											{this.context.darkMode && (
												<div className="absolute -top-1.5 left-3">
													<FontAwesomeIcon icon={faMoon} />
												</div>
											)}
										</XyzTransition>
									</div>
								</button>
							</div>
						</div>
					</div>
					<div className="p-6 4xl:container">
						<Routes>
							<Route
								path="/"
								element={
									<>
										{this.context.currentUser &&
											this.enabledRoute(2, 5) && (
												<Navigate to="./stream/" replace />
											)}
										<div>Please contact an Admin for your Role!</div>
									</>
								}
							/>
							{this.context.currentUser && this.enabledRoute(1, 3) && (
								<Route
									path="/admin/games"
									element={
										<AllPicText
											dbActions={this.dbActions}
											state={this.state}
											changeCurrentAPI={this.changeCurrentAPI}
											changeOverlay={this.changeOverlay}
											overlayArray={[
												"name",
												"pic",
												"color",
												"disabled",
											]}
											changeCurrentOverlayArray={
												this.changeCurrentOverlayArray
											}
											api="content/games"
											reload={this.state.reload}
										/>
									}
								/>
							)}
							{this.context.currentUser && this.enabledRoute(1, 3) && (
								<Route
									path="/admin/users"
									element={
										<AllDataList
											dbActions={this.dbActions}
											state={this.state}
											changeCurrentAPI={this.changeCurrentAPI}
											changeOverlay={this.changeOverlay}
											overlayArray={[
												"username",
												"firstName",
												"lastName",
												"email",
												"password",
												"country",
												"level",
												"subscription",
												"socials",
												"pic",
											]}
											changeCurrentOverlayArray={
												this.changeCurrentOverlayArray
											}
											setQuery={this.setQuery}
											deleteFilter={this.deleteFilter}
											api="user/manager"
											reload={this.state.reload}
										/>
									}
								/>
							)}
							{this.context.currentUser && this.enabledRoute(1, 3) && (
								<Route
									path="/admin/orgas"
									element={
										<AllPicText
											dbActions={this.dbActions}
											state={this.state}
											changeCurrentAPI={this.changeCurrentAPI}
											changeOverlay={this.changeOverlay}
											overlayArray={[
												"name",
												"color",
												"faceitID",
												"socials",
												"admins",
												"pic",
											]}
											changeCurrentOverlayArray={
												this.changeCurrentOverlayArray
											}
											api="content/orgas"
											reload={this.state.reload}
										/>
									}
								/>
							)}
							{this.context.currentUser && this.enabledRoute(1, 3) && (
								<Route
									path="/admin/seasons"
									element={
										<div>Coming Soon!</div>
										// <AllPicText
										// 	dbActions={this.dbActions}
										// 	state={this.state}
										// 	changeCurrentAPI={this.changeCurrentAPI}
										// 	changeOverlay={this.changeOverlay}
										// 	overlayArray={[
										// 		"name",
										// 		"orga",
										// 		"game",
										// 		"partner",
										// 		"tournaments",
										// 	]}
										// 	changeCurrentOverlayArray={
										// 		this.changeCurrentOverlayArray
										// 	}
										// 	api="content/seasons"
										// 	reload={this.state.reload}
										// />
									}
								/>
							)}
							{this.context.currentUser && this.enabledRoute(1, 3) && (
								<Route
									path="/admin/streams"
									element={
										<AllPicText
											dbActions={this.dbActions}
											state={this.state}
											changeCurrentAPI={this.changeCurrentAPI}
											changeOverlay={this.changeOverlay}
											overlayArray={[
												"name",
												"link",
												"participants",
												"pic",
												"color",
											]}
											changeCurrentOverlayArray={
												this.changeCurrentOverlayArray
											}
											api="content/streams"
											reload={this.state.reload}
										/>
									}
								/>
							)}
							{this.context.currentUser && this.enabledRoute(1, 3) && (
								<Route
									path="/tournament/*"
									element={
										<MyTournament
											dbActions={this.dbActions}
											state={this.state}
											changeCurrentAPI={this.changeCurrentAPI}
											api="content/myTournament"
											reload={this.state.reload}
										/>
									}
								/>
							)}
							{this.context.currentUser && this.enabledRoute(2, 5) && (
								<Route
									path="/db/players"
									element={
										<AllDataList
											dbActions={this.dbActions}
											state={this.state}
											changeCurrentAPI={this.changeCurrentAPI}
											changeOverlay={this.changeOverlay}
											overlayArray={[
												"gameName",
												"firstName",
												"lastName",
												"active",
												"country",
												"socials",
												"games",
												"pic",
											]}
											changeCurrentOverlayArray={
												this.changeCurrentOverlayArray
											}
											setQuery={this.setQuery}
											deleteFilter={this.deleteFilter}
											api="db/players"
											reload={this.state.reload}
										/>
									}
								/>
							)}
							{this.context.currentUser && this.enabledRoute(2, 5) && (
								<Route
									path="/db/teams"
									element={
										<AllDataList
											dbActions={this.dbActions}
											state={this.state}
											changeCurrentAPI={this.changeCurrentAPI}
											changeOverlay={this.changeOverlay}
											overlayArray={[
												"nameLong",
												"nameShort",
												"active",
												"country",
												"socials",
												"games",
												"pic",
											]}
											changeCurrentOverlayArray={
												this.changeCurrentOverlayArray
											}
											setQuery={this.setQuery}
											deleteFilter={this.deleteFilter}
											api="db/teams"
											reload={this.state.reload}
										/>
									}
								/>
							)}
							{this.context.currentUser && this.enabledRoute(2, 5) && (
								<Route path="/stream/*" element={<MyStream />} />
							)}
							{this.context.currentUser && (
								<Route
									path="*"
									element={<Navigate to="./" replace />}
								/>
							)}
						</Routes>
					</div>
				</div>
				{this.state.overlayOpen && (
					<OverlayContainer
						selectedData={this.state.selectedData}
						saveURL={(url) => {
							let selectedData = this.state.selectedData;
							selectedData.pic = url;
							this.setState({ selectedData: selectedData });
						}}
						changeOverlay={this.changeOverlay}
						overlayArray={this.state.overlayArray}
						onChange={this.changeSelectedData}
						addCurrent={(e) => {
							e.preventDefault();
							this.dbActions("POST", this.state.currentAPI);
						}}
						saveCurrent={(e) => {
							e.preventDefault();
							this.dbActions("PUT", this.state.currentAPI);
						}}
						deleteCurrent={(e) => {
							e.preventDefault();
							this.dbActions("DELETE", this.state.currentAPI);
						}}
						action={this.state.overlayAction}
						currentAPI={this.state.currentAPI}
					/>
				)}
			</div>
		);
	}
}
