import React, { Component } from "react";
import Stage from "./Stage";

/**
 * Bracket
 *
 * changeToggle: Function
 * bracket: Array
 * tournamentInfos: Object
 * matches: Array
 */
export default class Bracket extends Component {
	stageTitles = ["Quater-Finals", "Semi-Finals", "Finals"];

	rounds = () => {
		if (
			this.props.bracket.length === 0 ||
			this.props.tournamentInfos.rounds === 2
		) {
			return this.props.tournamentInfos.rounds;
		}
		return 3;
	};

	title = (index) => {
		return this.stageTitles[index + this.stageTitles.length - this.rounds()];
	};

	dummyGame = {
		teams: {
			faction1: {
				name: "",
				avatar: "",
			},
			faction2: {
				name: "",
				avatar: "",
			},
		},
		results: {
			score: {
				faction1: 0,
				faction2: 0,
			},
			winner: "",
		},
	};

	getStages = (bracket) => {
		let matches = [];
		bracket.forEach((match) => {
			if (match === "") {
				matches.push(this.dummyGame);
			} else {
				matches.push(this.props.matches.find((m) => m.match_id === match));
			}
		});
		let leftOver =
			this.props.tournamentInfos.rounds === 2
				? 3
				: bracket.length === 0
				? 7
				: bracket.length;
		let stages = [];
		for (let i = 0; leftOver > 0; i++) {
			leftOver = (leftOver + 1) / 2;
			stages.push([]);
			for (let j = 0; j < leftOver; j++) {
				stages[i].push({ matches: matches[0] });
				matches.shift();
			}
			leftOver = leftOver - 1;
		}
		let winner = "";
		const lastMatch = stages[stages.length - 1][0].matches;
		if (lastMatch.results && lastMatch.results.winner) {
			winner = lastMatch.teams[lastMatch.results.winner].name;
		}

		stages.push([{ winner: winner }]);
		return stages;
	};

	polyLine = (points, p1, p2, p3, p4, hor) => {
		return (
			<polyline
				points={`0,${points[p1][p2]} ${hor},${points[p3][p4]}`}
				stroke="white"
				strokeWidth={hor === 0 ? "5" : "3"}
				fill="none"
			/>
		);
	};

	bracketLines = () => {
		let pathPoints = [];
		switch (this.rounds()) {
			case 2:
				pathPoints = [[212, 274, 440], [506, 668, 730], [471]];
				return (
					<>
						<div className="w-full h-full absolute left-[454px] z-50">
							<svg className="w-full h-full">
								{this.polyLine(pathPoints, 0, 0, 0, 0, 109)}
								{this.polyLine(pathPoints, 0, 1, 0, 1, 109)}
								{this.polyLine(pathPoints, 1, 1, 1, 1, 109)}
								{this.polyLine(pathPoints, 1, 2, 1, 2, 109)}
							</svg>
						</div>
						<div className="w-full h-full absolute left-[563px] z-50">
							<svg className="w-full h-full">
								{this.polyLine(pathPoints, 0, 0, 0, 2, 0)}
								{this.polyLine(pathPoints, 0, 2, 0, 2, 109)}
								{this.polyLine(pathPoints, 1, 0, 1, 2, 0)}
								{this.polyLine(pathPoints, 1, 0, 1, 0, 109)}
							</svg>
						</div>
						<div className="w-full h-full absolute left-[1030px] z-50">
							<svg className="w-full h-full">
								{this.polyLine(pathPoints, 0, 2, 0, 2, 109)}
								{this.polyLine(pathPoints, 1, 0, 1, 0, 109)}
							</svg>
						</div>
						<div className="w-full h-full absolute left-[1139px] z-50">
							<svg className="w-full h-full">
								{this.polyLine(pathPoints, 0, 2, 1, 0, 0)}
								{this.polyLine(pathPoints, 2, 0, 2, 0, 109)}
							</svg>
						</div>
					</>
				);
			case 3:
				pathPoints = [
					[99, 161, 214, 276, 329, 391],
					[551, 613, 666, 728, 781, 843],
					[439, 471, 503],
				];
				return (
					<>
						<div className="w-full h-full absolute left-[453px] z-50">
							<svg className="w-full h-full">
								{this.polyLine(pathPoints, 0, 0, 0, 0, 41)}
								{this.polyLine(pathPoints, 0, 1, 0, 1, 41)}
								{this.polyLine(pathPoints, 0, 4, 0, 4, 41)}
								{this.polyLine(pathPoints, 0, 5, 0, 5, 41)}
								{this.polyLine(pathPoints, 1, 0, 1, 0, 41)}
								{this.polyLine(pathPoints, 1, 1, 1, 1, 41)}
								{this.polyLine(pathPoints, 1, 4, 1, 4, 41)}
								{this.polyLine(pathPoints, 1, 5, 1, 5, 41)}
							</svg>
						</div>
						<div className="w-full h-full absolute left-[492px] z-50">
							<svg className="w-full h-full">
								{this.polyLine(pathPoints, 0, 0, 0, 2, 0)}
								{this.polyLine(pathPoints, 0, 2, 0, 2, 41)}
								{this.polyLine(pathPoints, 0, 3, 0, 5, 0)}
								{this.polyLine(pathPoints, 0, 3, 0, 3, 41)}
								{this.polyLine(pathPoints, 1, 0, 1, 2, 0)}
								{this.polyLine(pathPoints, 1, 2, 1, 2, 41)}
								{this.polyLine(pathPoints, 1, 3, 1, 5, 0)}
								{this.polyLine(pathPoints, 1, 3, 1, 3, 41)}
							</svg>
						</div>
						<div className="w-full h-full absolute left-[885px] z-50">
							<svg className="w-full h-full">
								{this.polyLine(pathPoints, 0, 2, 0, 2, 41)}
								{this.polyLine(pathPoints, 0, 3, 0, 3, 41)}
								{this.polyLine(pathPoints, 1, 2, 1, 2, 41)}
								{this.polyLine(pathPoints, 1, 3, 1, 3, 41)}
							</svg>
						</div>
						<div className="w-full h-full absolute left-[924px] z-50">
							<svg className="w-full h-full">
								{this.polyLine(pathPoints, 0, 2, 2, 0, 0)}
								{this.polyLine(pathPoints, 2, 0, 2, 0, 41)}
								{this.polyLine(pathPoints, 2, 2, 1, 3, 0)}
								{this.polyLine(pathPoints, 2, 2, 2, 2, 41)}
							</svg>
						</div>
						<div className="w-full h-full absolute left-[1317px] z-50">
							<svg className="w-full h-full">
								{this.polyLine(pathPoints, 2, 0, 2, 0, 41)}
								{this.polyLine(pathPoints, 2, 2, 2, 2, 41)}
							</svg>
						</div>
						<div className="w-full h-full absolute left-[1356px] z-50">
							<svg className="w-full h-full">
								{this.polyLine(pathPoints, 2, 0, 2, 2, 0)}
								{this.polyLine(pathPoints, 2, 1, 2, 1, 41)}
							</svg>
						</div>
					</>
				);
			default:
				return <></>;
		}
	};

	render() {
		return (
			<div>
				<div
					className={
						(this.rounds() === 3 ? "left-24" : "left-16") +
						" w-full h-full uppercase italic flex p-24 mt-10 absolute left-24"
					}
				>
					{this.getStages(this.props.bracket).map((stage, i) => (
						<Stage
							key={i + "-stage"}
							stage={stage}
							index={i}
							title={this.title(i)}
							tournamentInfos={this.props.tournamentInfos}
							className={`w-1/2 relative text-center`}
						/>
					))}
					{this.bracketLines()}
				</div>
			</div>
		);
	}
}
