import React, { Component } from "react";
import Headline from "../../Headline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

/**
 * Schedule
 *
 * matches: Array
 * scheduleRound: Number
 * schedulePage: Number
 * tournamentInfos: Object
 */
export default class Schedule extends Component {
	stages = [
		"Round of 32",
		"Round of 16",
		"Quarterfinals",
		"Semifinals",
		"Finals",
	];
	toMonthName = (monthNumber) => {
		const date = new Date();
		date.setMonth(monthNumber - 1);

		return date.toLocaleString("en-US", {
			month: "long",
		});
	};
	formatTime = () => {
		const date = new Date(
			(this.props.tournamentInfos.schedule[this.props.scheduleRound].date /
				1000) *
				1000
		);
		var hours = date.getHours();
		var minutes = date.getMinutes();
		var rounded = 15;
		var m =
			"0" + (((((minutes + rounded / 2) / rounded) | 0) * rounded) % 60);
		var h = "0" + ((((minutes / 105 + 0.5) | 0) + hours) % 24);
		return (
			h.substring(h.length - 2) + ":" + m.substring(m.length - 2) + " CEST"
		);
	};
	finishedMatch = (match) => {
		if (match.status === "FINISHED") {
			if (match.best_of === 1) {
			}
			return `${match.results.score.faction1} : ${match.results.score.faction2}`;
		} else if (match.status === "CANCELLED") {
			return <FontAwesomeIcon icon={faBan} size="xs" />;
		} else return "0 : 0";
	};
	render() {
		return (
			<div className="px-[100px]">
				<div className="relative">
					<Headline
						className="w-full text-center text-7xl italic mt-32 uppercase"
						title="Schedule"
					/>
					<div className="absolute mt-16 w-full text-center flex flex-col">
						<div className="w-full mb-6">
							<div className="w-full h-20 bg-white text-black flex items-center justify-center text-6xl uppercase">
								{`Road to Levelup - ${
									this.stages[this.props.scheduleRound - 1]
								} - Europe Edition`}
							</div>
							<div className="w-full h-20 bg-tertiarty font-industry text-secondary flex items-center justify-center text-4xl">
								{this.formatTime()}
							</div>
						</div>
						<div className="w-full grid grid-cols-2 justify-between">
							{this.props.matches
								.filter(
									(match) => match.round === this.props.scheduleRound
								)
								.map((match, i) => (
									<div
										className={
											"flex justify-start h-16 " +
											(i % 2 === 0 ? "" : "justify-end")
										}
										key={i + "-matches-schedule"}
									>
										<div className="w-[360px] bg-tertiarty flex justify-center items-center uppercase text-3xl">
											{match.teams.faction1.name}
										</div>
										<div className="w-[112px] bg-white text-tertiarty flex justify-center items-center text-3xl font-industry pt-1.5">
											{this.finishedMatch(match)}
										</div>
										<div className="w-[360px] bg-tertiarty flex justify-center items-center uppercase text-3xl">
											{match.teams.faction2.name}
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
