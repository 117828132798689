import React, { Component } from "react";

export default class Sponsor extends Component {
	render() {
		return (
			<div className="absolute top-[880px] left-0 w-full">
				<img
					src={require("../../images/PartnerLogos.png")}
					alt="Sponsoren"
					className="w-full"
				/>
			</div>
		);
	}
}
