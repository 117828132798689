import React, { Component } from "react";
import Match from "./Match";

/**
 * Stage
 *
 * className: String
 * stage: Array
 * title: String
 * winner: String
 * index: Number
 * tournamentInfos: Object
 */
export default class Stage extends Component {
	render() {
		return (
			<div className={this.props.className}>
				<div className="h-full grid gap-4 content-around relative">
					{this.props.stage[0].matches &&
						this.props.stage.map((match, i) => (
							<Match
								key={i + "-Match"}
								index={i}
								stageIndex={this.props.index}
								match={match.matches}
								tournamentInfos={this.props.tournamentInfos}
								className="not-italic w-[360px] relative"
								title={this.props.title}
							/>
						))}
					{!this.props.stage[0].matches && (
						<Match
							key="champion-brackets"
							index="winner"
							winner={this.props.stage[0].winner}
							className="not-italic w-[360px] relative"
						/>
					)}
				</div>
			</div>
		);
	}
}
