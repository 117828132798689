import React, { Component } from "react";

import Button from "../../../shared/components/UIElements/Button";

/**
 * EachMap
 *
 * loadPic: Function
 * saveTeam: Function
 * savePick: Function
 * currentMap: Object
 * mapveto: Array
 * map: String
 */
export default class Map extends Component {
	render() {
		return (
			<div
				key={this.props.map + "right"}
				className="text-black h-32 flex items-center border-2 rounded-md border-gray-50 p-2"
			>
				<div className="h-full w-1/3 items-center justify-center flex flex-col">
					<img
						className="object-cover h-16 w-16"
						src={this.props.loadPic("pins", this.props.map)}
						alt={this.props.map}
					/>
					<div className="text-white first-letter:uppercase mt-2">
						{this.props.map}
					</div>
				</div>
				<div className="flex justify-around w-2/3 h-full">
					<div className="flex flex-col place-content-around h-full">
						<Button
							onClick={() => this.props.saveTeam(this.props.map, 1)}
							smallPadding
							disabled={
								this.props.currentMap.name === this.props.map ||
								typeof this.props.mapveto.find(
									(veto) => veto.name === this.props.map
								) !== "undefined"
							}
							green
						>
							Team 1
						</Button>
						<Button
							onClick={() => this.props.saveTeam(this.props.map, 2)}
							smallPadding
							disabled={
								this.props.currentMap.name === this.props.map ||
								typeof this.props.mapveto.find(
									(veto) => veto.name === this.props.map
								) !== "undefined"
							}
							secondary
						>
							Team 2
						</Button>
						<Button
							onClick={() => this.props.saveTeam(this.props.map, 0)}
							disabled={
								this.props.currentMap.name === this.props.map ||
								typeof this.props.mapveto.find(
									(veto) => veto.name === this.props.map
								) !== "undefined"
							}
							smallPadding
							red
						>
							Decider
						</Button>
					</div>
					<div className="flex flex-col place-content-around h-full">
						<Button
							onClick={() => this.props.savePick(true)}
							disabled={
								this.props.currentMap.name !== this.props.map ||
								typeof this.props.mapveto.find(
									(veto) => veto.name === this.props.map
								) !== "undefined"
							}
							smallPadding
							green
						>
							Pick
						</Button>
						<Button
							onClick={() => this.props.savePick(false)}
							disabled={
								this.props.currentMap.name !== this.props.map ||
								typeof this.props.mapveto.find(
									(veto) => veto.name === this.props.map
								) !== "undefined"
							}
							smallPadding
							red
						>
							Ban
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
