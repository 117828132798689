import React, { Component } from "react";

import Button from "../Button";
import FormInput from "./FormInput";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faClose } from "@fortawesome/free-solid-svg-icons";

/**
 * FormPartner
 * 
 * entry: Object
 * changeArray: Function
 * index: Number
 * actions: Function
 */
export default class FormPartner extends Component {
	render() {
		return (
			<>
				<div className="col-span-3 row-span-1 flex items-center justify-center text-gray-900">
					{this.props.entry.pic && (
						<img
							src={this.props.entry.pic}
							alt="PIC"
							className="p-1 bg-gray-50"
						/>
					)}
				</div>
				<div className="col-span-7 row-span-1 flex items-center justify-center">
					<FormInput
						className="w-full"
						label={false}
						name="name"
						placeholder="Name"
						type="text"
						onChange={(e) =>
							this.props.changeArrays(e, "partner", this.props.index)
						}
						data={this.props.entry}
					/>
				</div>
				<div className="col-span-2 row-span-1 flex items-center justify-center">
					<Button
						red
						onClick={(e) => {
							e.preventDefault();
							this.props.actions(
								"partner",
								"remove",
								this.props.index
							);
						}}
					>
						<FontAwesomeIcon icon={faClose} />
					</Button>
				</div>
				<div className="col-span-2 row-span-1 flex items-center justify-center">
					<Button
						secondary
						onClick={(e) => {
							e.preventDefault();
							this.props.actions("partner", "upload", this.props.index);
						}}
					>
						<FontAwesomeIcon
							icon={faEdit}
							className="text-gray-600 dark:text-gray-50 "
						/>
					</Button>
				</div>
				<div className="col-span-10 row-span-1 flex items-center justify-center">
					<FormInput
						className="w-full"
						label={false}
						name="pic"
						placeholder="URL"
						type="text"
						onChange={(e) =>
							this.props.changeArrays(e, "partner", this.props.index)
						}
						data={this.props.entry}
					/>
				</div>
			</>
		);
	}
}
