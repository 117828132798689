import React from "react";

import { ThemeContext } from "../../shared/context/theme-context.js";
import socketIOClient from "socket.io-client";
import Loading from "../../shared/components/UIElements/Loading.jsx";
import axios from "axios";
import { NavLink, Routes, Route, Navigate } from "react-router-dom";
import Teams from "./Teams.jsx";

export default class MyTournament extends React.Component {
	static contextType = ThemeContext;
	socket = {};

	state = {
		currentStream: "",
		oldStream: "",
		currentOrganizer: "",
		currentTournament: "",
		currentMatch: {},
		currentView: "",
		time: "",
		tournamentInfos: {},
		tournaments: [],
		mapveto: [],
		matches: [],
		participants: [],
		visual: [],
		loadTournament: {
			offset: 0,
			limit: 100,
		},
		loadMatches: {
			offset: 0,
			limit: 100,
		},
		schedulePage: 1,
		scheduleRound: 1,
		bracket: [],
		teams: [],
	};

	// Helper for Functions
	setStream = (data) => {
		this.setState({
			currentStream: data.stream,
			oldStream: data.stream,
			mapveto: data.veto,
			matches: data.matches,
			participants: data.participants,
			currentTournament: data.tournamentID,
			currentMatch: data.currentMatch,
			currentView: data.view,
			currentOrganizer: data.organizerID,
			tournamentInfos: data.tournamentInfos,
			time: data.time,
			tournaments: data.tournaments,
			visual: data.visual,
			bracket: data.bracket,
		});
	};

	// Set loading to true and load from DB
	loadDB = () => {
		this.props.changeCurrentAPI(this.props.api);
		this.setState(
			{ loading: true },
			this.props.dbActions("GET", this.props.api, () =>
				this.setState({ loading: false })
			)
		);
	};

	// Socket emitter on Stream Change
	changeStream = (data) => {
		this.socket.emit("changeStream", data, (resp) => {
			this.setStream(resp);
			// if (typeof data.old === "undefined") {
			// 	this.changeOrganizer(cb.organizerID);
			// }
		});
	};

	loadTeamsFromDB = () => {
		const teams = [];
		for (let i = 0; i < this.teams.length; i++) {
			const el = this.teams[i];
			axios
				.get(`${this.context.server}/db/teams/${el}`)
				.then((resp) => teams.push(resp.data.data));
		}
		this.setState({ teams: teams });
	};

	componentDidMount = () => {
		this.loadTeamsFromDB();
		this.loadDB();

		this.socket = socketIOClient(this.context.server);

		this.changeStream({ new: "alpenSceneTV" });

		this.socket.on("initiallyConnected", () => {
			this.setState({ currentStream: "" });
		});
		this.socket.on("changeTournament", (stream) => {
			this.setStream(stream);
		});
		this.socket.on("changeMapveto", (mapveto) => {
			this.setState({ mapveto: mapveto });
		});
		this.socket.on("changeMatch", (match) => {
			this.setState({ currentMatch: match, mapveto: [] });
		});
		this.socket.on("changeView", (view) => {
			this.setState({ currentView: view });
		});
		this.socket.on("changeParticipants", (participants) => {
			this.setState({ participants: participants });
		});
		this.socket.on("changeOrganizer", (data) => {
			this.setStream(data);
		});
		this.socket.on("changeTime", (data) => {
			this.setState({ time: data });
		});
		this.socket.on("changeVisual", (visual) => {
			this.setState({ visual: visual });
		});
		this.socket.on("changeSchedule", (schedule) => {
			this.setState({
				schedulePage: schedule.page,
				scheduleRound: schedule.round,
			});
		});
		this.socket.on("changeBracket", (bracket) => {
			this.setState({ bracket: bracket });
		});
	};

	componentDidUpdate = (oldProps) => {
		if (oldProps.state.selectedData !== this.props.state.selectedData) {
			return;
		}
		if (!this.state.loading && this.props.state !== oldProps.state) {
			this.loadDB();
		}
	};

	componentWillUnmount = () => {
		this.socket.off("initiallyConnected");
		this.socket.off("changeTournament");
		this.socket.off("changeMapveto");
		this.socket.off("changeMatch");
		this.socket.off("changeView");
		this.socket.off("changeParticipants");
		this.socket.off("changeOrganizer");
		this.socket.off("changeTime");
		this.socket.off("changeVisual");
		this.socket.off("changeSchedule");
		this.socket.off("changeBracket");
	};

	teams = [
		"629b2ea7a077cd134893e096",
		"629b2fa8a077cd134893e097",
		"629b5c0bdc26ad12ec58b507",
		"629c804f63b5181270a2f6cf",
	];

	links = ["home", "teams", "matches", "tree"];

	render() {
		return (
			<Loading
				loading={
					this.state.currentStream === "" && this.state.teams.length === 0
				}
			>
				<div className="w-full grid gap-3 grid-flow-col text-center mb-4">
					{this.links.map((link, i) => (
						<NavLink
							key={i + "-link-header"}
							className={(isActive) =>
								(isActive.isActive
									? "bg-primary text-white border"
									: "dark:border-white border-tertiarty border") +
								" py-4 rounded-lg"
							}
							to={"./" + (link === "home" ? "" : link)}
						>
							<p className="first-letter:uppercase">{link}</p>
						</NavLink>
					))}
				</div>
				<Routes>
					<Route path="/" element={<>Test</>} />
					<Route path="/teams" element={<Teams teams={this.state.teams} />} />
					<Route path="/matches" element={<>matches</>} />
					<Route path="/tree" element={<>tree</>} />

					<Route path="*" element={<Navigate to="./" />} />
				</Routes>
				<div>Welche Teams nehmen teil.</div>
				<div>Matches generieren im Round Robin.</div>
				<div>Current Match auswählen können.</div>
				<div>Ergebnisse eintragen.</div>
			</Loading>
		);
	}
}
