import React, { Component } from "react";
import Header from "../Header";
import Rounds from "./Rounds";
import BracketInput from "./BracketInput";

/**
 * Matches
 *
 * matches: Array
 * currentMatch: Object
 * changeCurrentMatch: Function
 * changeBracket: Function
 * bracket: Array
 */
export default class Matches extends Component {
	maxRounds = (matches) => {
		let array = [];
		matches.forEach((match) => {
			array.push(Number(match.round));
		});
		const max = Math.max.apply(null, array);
		let rounds = [];
		for (let i = 0; i < max; i++) {
			const round = matches.filter((match) => match.round === i + 1);
			rounds.push(round);
		}
		return rounds;
	};

	render() {
		return (
			<div className="">
				<Header headline="Matches" />
				<BracketInput
					changeBracket={this.props.changeBracket}
					matches={this.props.matches}
					bracket={this.props.bracket}
				/>
				<div className="flex space-x-2 mt-6 w-full">
					{this.maxRounds(this.props.matches).map((round, i) => (
						<Rounds
							round={round}
							key={i + "round"}
							index={i + 1}
							currentMatch={this.props.currentMatch}
							changeCurrentMatch={this.props.changeCurrentMatch}
						/>
					))}
				</div>
			</div>
		);
	}
}
