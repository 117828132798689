import React, { Component } from "react";
import MatchTime from "./MatchTime";
import Headline from "../../Headline";

/**
 * Match
 *
 * match: Object
 * stageIndex: Number
 * tournamentInfos: Object
 * className: String
 * title: String
 * winner: String
 */
export default class Match extends Component {
	getMatchTime = () => {
		if (this.props.match) {
			if (typeof this.props.match.status === "undefined") {
				const values = Object.values(this.props.tournamentInfos.schedule);
				const schedule = values.filter((s, i) => {
					return i >= values.length - 3;
				});
				return schedule[this.props.stageIndex].date / 1000;
			}
			if (this.props.match.status === "CANCELLED") {
				return "CANCELLED";
			} else if (this.props.match.scheduled_at) {
				return this.props.match.scheduled_at;
			} else if (this.props.match.started_at) {
				return this.props.match.started_at;
			} else return this.props.match.finished_at;
		} else return "FAILED";
		// SUBSTITUTION
	};
	teamName = (faction) => {
		const team = `faction${faction}`;
		if (this.props.match.teams) {
			return this.props.match.teams[team].name;
		} else {
			return "";
		}
	};
	render() {
		return (
			<div className={this.props.className}>
				{this.props.index === 0 && (
					<Headline
						title={this.props.title}
						className="absolute -top-16 italic text-center w-full text-3xl"
					/>
				)}
				<div className="mb-4 flex justify-center">
					<MatchTime matchTime={this.getMatchTime()} />
				</div>
				{this.props.match && (
					<div className="bg-white text-black mb-2 h-14 flex items-center justify-center text-2xl">
						{this.teamName(1)}
					</div>
				)}
				{this.props.match && (
					<div className="bg-white text-black mb-2 h-14 flex items-center justify-center text-2xl">
						{this.teamName(2)}
					</div>
				)}
				{/* {this.props.match && (
					<svg className="absolute left-[395px] -bottom-[50px]">
						<polyline
							points="0,0 0,65"
							stroke="white"
							strokeWidth="3"
							fill="none"
						/>
					</svg>
				)} */}
				{!this.props.match && (
					<div className="bg-white text-black mb-2 h-14 flex items-center justify-center text-2xl">
						{this.props.winner}
					</div>
				)}
			</div>
		);
	}
}
