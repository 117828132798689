import React, { Component } from "react";
import SceneButton from "./SceneButton";
import { NavLink } from "react-router-dom";

/**
 * Views
 *
 * state: Object
 * changeView: Function
 * changeVisual: Function
 */
export default class Views extends Component {
	views = [
		{
			name: "facecams",
			allowed: ["mapveto", "sponsor", "nextMatch", "tournamentLogo"],
		},
		// {
		// 	name: "ingame",
		// 	allowed: [],
		// },
		{
			name: "starting",
			allowed: ["sponsor", "nextMatch"],
		},
		{
			name: "bracket",
			allowed: ["tournamentLogo"],
		},
		// {
		// 	name: "schedule",
		// 	allowed: ["sponsor", "nextMatch", "tournamentLogo"],
		// },
	];

	checkDisabled = (view) => {
		// if (view === "mapveto" && this.props.state.visual && this.props.state.visual.includes("sponsor")) {
		// 	return true;
		// } else if (
		// 	view === "sponsor" &&
		// 	this.props.state.visual.includes("mapveto")
		// ) {
		// 	return true;
		// } else
		return false;
	};
	render() {
		return (
			<>
				<div className="space-x-2 space-y-4">
					<div className="mr-4">Szenen:</div>
					{this.views.map((view, i) => (
						<SceneButton
							key={i + "-toggleView"}
							onClick={this.props.changeView}
							view={view.name}
							on={this.props.state.currentView === view.name}
						/>
					))}
				</div>
				<div className="space-x-2 space-y-4">
					<div className="mr-4">Toggle:</div>

					{this.views
						.find((view) => view.name === this.props.state.currentView)
						.allowed.map((view, i) => (
							<SceneButton
								key={i + "-toggleVisual"}
								onClick={this.props.changeVisual}
								view={view}
								// on={this.props.state.visual.includes(view)}
								disabled={this.checkDisabled(view)}
							/>
						))}
				</div>
				<div className="mt-4 ">
					<NavLink
						to="/view/alpenSceneTV/main"
						className="p-4 bg-green-400 rounded-lg"
					>
						To Views
					</NavLink>
				</div>
			</>
		);
	}
}
