import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faTrophy } from "@fortawesome/free-solid-svg-icons";

/**
 * MatchTime
 *
 * matchTime: Number
 */
export default class MatchTime extends Component {
	toMonthName = (monthNumber) => {
		const date = new Date();
		date.setMonth(monthNumber - 1);

		return date.toLocaleString("en-US", {
			month: "long",
		});
	};
	formatTime = () => {
		if (typeof this.props.matchTime === "undefined") {
			return (
				<>
					<div>
						<FontAwesomeIcon icon={faBan} size="xs" />
					</div>
					<div className="ml-3">CANCELLED</div>
				</>
			);
		}
		if (typeof this.props.matchTime !== "string") {
			const date = new Date(this.props.matchTime * 1000);
			var day = "0" + date.getDate();
			var month = this.toMonthName(date.getMonth() + 1);
			var hours = date.getHours();
			var minutes = date.getMinutes();
			var rounded = 15;
			var m =
				"0" + (((((minutes + rounded / 2) / rounded) | 0) * rounded) % 60);
			var h = "0" + ((((minutes / 105 + 0.5) | 0) + hours) % 24);
			return (
				day.substring(day.length - 2) +
				" " +
				month +
				" - " +
				h.substring(h.length - 2) +
				":" +
				m.substring(m.length - 2) +
				" CEST"
			);
		} else if (this.props.matchTime === "CANCELLED") {
			return (
				<>
					<div>
						<FontAwesomeIcon icon={faBan} size="xs" />
					</div>
					<div className="ml-3">CANCELLED</div>
				</>
			);
		} else {
			return (
				<>
					<div>
						<FontAwesomeIcon icon={faTrophy} size="xs" />
					</div>
					<div className="ml-3">Champion</div>
				</>
			);
		}
	};
	render() {
		return (
			<div className="w-full bg-black h-11 items-center flex justify-center text-secondary font-teko font-normal text-3xl pt-1">
				{this.formatTime()}
			</div>
		);
	}
}
