import React, { Component } from "react";

import Map from "./Map";

/**
 * EachMap
 *
 * className: String
 * loadPic: Function
 * saveTeam: Function
 * savePick: Function
 * currentMap: Object
 * mapveto: Array
 */
export default class EachMap extends Component {
	state = {
		allMaps: [
			"ancient",
			"dust2",
			"inferno",
			"mirage",
			"nuke",
			"overpass",
			"vertigo",
		],
	};
	render() {
		return (
			<div className={this.props.className}>
				<div className="w-full text-center text-xl h-8">All Maps</div>
				<div className="flex flex-col space-y-2">
					{this.state.allMaps
						.sort((a, b) => a - b)
						.map((map) => (
							<Map
								key={map + "-eachMap"}
								map={map}
								loadPic={this.props.loadPic}
								saveTeam={this.props.saveTeam}
								savePick={this.props.savePick}
								currentMap={this.props.currentMap}
								mapveto={this.props.mapveto}
							/>
						))}
				</div>
			</div>
		);
	}
}
