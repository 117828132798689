import React, { Component } from "react";
import Button from "../../../shared/components/UIElements/Button";
import FormInput from "../../../shared/components/UIElements/Forms/FormInput";

/**
 * BracketInput
 *
 * matches: Array
 * changeBracket: Function
 * bracket: Array
 */
export default class BracketInput extends Component {
	constructor(props) {
		super(props);
		this.onChangeHandler = this.onChangeHandler.bind(this);
	}

	state = {
		qf1: "",
		qf2: "",
		qf3: "",
		qf4: "",
		sf1: "",
		sf2: "",
		final: "",
	};

	setMatches = () => {
		const { qf1, qf2, qf3, qf4, sf1, sf2, final } = this.state;
		this.props.changeBracket([qf1, qf2, qf3, qf4, sf1, sf2, final]);
	};

	onChangeHandler = (e) => {
		e.preventDefault();
		const name = e.target.name;
		const value = e.target.value;
		this.setState({ [name]: value });
	};

	getMatch = (matchID) => {
		if (matchID === "") {
			return "";
		}
		const currentMatch = this.props.matches.find(
			(match) => match.match_id === matchID
		);
		if (typeof currentMatch === "undefined") {
			return "";
		}
		const team1 = currentMatch.teams.faction1.name;
		const team2 = currentMatch.teams.faction2.name;
		return `${team1} vs. ${team2}`;
	};

	componentDidMount = () => {
		const { bracket } = this.props;
		const array = [];
		for (const [key] of Object.entries(this.state)) {
			array.push(key);
		}
		for (let i = 0; i < bracket.length; i++) {
			const element = bracket[i];
			this.setState({ [array[i]]: element });
		}
	};

	render() {
		return (
			<div className="flex h-96 space-x-4 text-black">
				<div className="flex flex-col h-full justify-around">
					<div>
						<FormInput
							className="w-80"
							type="text"
							name="qf1"
							data={this.state}
							label="Quarter Final 1"
							onChange={this.onChangeHandler}
						/>
						<div className="text-white">
							{this.getMatch(this.state.qf1)}
						</div>
					</div>
					<div>
						<FormInput
							className="w-80"
							type="text"
							name="qf2"
							data={this.state}
							label="Quarter Final 2"
							onChange={this.onChangeHandler}
						/>
						<div className="text-white">
							{this.getMatch(this.state.qf2)}
						</div>
					</div>
					<div>
						<FormInput
							className="w-80"
							type="text"
							name="qf3"
							data={this.state}
							label="Quarter Final 3"
							onChange={this.onChangeHandler}
						/>
						<div className="text-white">
							{this.getMatch(this.state.qf3)}
						</div>
					</div>
					<div>
						<FormInput
							className="w-80"
							type="text"
							name="qf4"
							data={this.state}
							label="Quarter Final 4"
							onChange={this.onChangeHandler}
						/>
						<div className="text-white">
							{this.getMatch(this.state.qf4)}
						</div>
					</div>
				</div>
				<div className="flex flex-col h-full justify-around">
					<div>
						<FormInput
							className="w-80"
							type="text"
							name="sf1"
							data={this.state}
							label="Semi Final 1"
							onChange={this.onChangeHandler}
						/>
						<div className="text-white">
							{this.getMatch(this.state.sf1)}
						</div>
					</div>
					<div>
						<FormInput
							className="w-80"
							type="text"
							name="sf2"
							data={this.state}
							label="Semi Final 2"
							onChange={this.onChangeHandler}
						/>
						<div className="text-white">
							{this.getMatch(this.state.sf2)}
						</div>
					</div>
				</div>
				<div className="flex flex-col h-full justify-around">
					<div>
						<FormInput
							className="w-80"
							type="text"
							name="final"
							data={this.state}
							label="Final"
							onChange={this.onChangeHandler}
						/>
						<div className="text-white">
							{this.getMatch(this.state.final)}
						</div>
					</div>
				</div>
				<Button green onClick={this.setMatches.bind(this)}>
					Save Bracket
				</Button>
			</div>
		);
	}
}
