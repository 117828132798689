import React, { Component } from "react";
import Match from "./Match";

/**
 * Rounds
 *
 * Round: Object
 * Index: Number
 * currentMatch: Object
 * changeCurrentMatch: Function
 */
export default class Rounds extends Component {
	render() {
		return (
			<div className="border flex flex-col w-96">
				<div className="mb-2">Round: {this.props.index}</div>
				<div className="flex flex-col space-y-2 justify-around h-full">
					{this.props.round.map((match) => (
						<Match
							match={match}
							key={match.match_id}
							currentMatch={this.props.currentMatch}
							changeCurrentMatch={this.props.changeCurrentMatch}
						/>
					))}
				</div>
			</div>
		);
	}
}
