import React from "react";
import { CircleSpinner } from "react-spinners-kit";

/**
 * Loading
 *
 * loading: Boolean
 * center: Boolean
 * children: <>
 */
export default class Loading extends React.Component {
	render() {
		return (
			<>
				{this.props.loading && (
					<div
						className={
							(this.props.className ? this.props.className : "dark:bg-dark-900 ") +
							" " +
							(this.props.center ? "h-screen " : "") +
							"flex justify-center items-center "
						}
					>
						<CircleSpinner
							size={this.props.center ? 60 : 30}
							color="#686769"
							loading={this.props.loading}
						/>
					</div>
				)}
				{this.props.children}
			</>
		);
	}
}
